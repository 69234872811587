import React, { useEffect, useState } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiText,
    EuiSpacer,
    EuiBadge,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiButton,
    EuiCallOut,
    EuiTabs,
    EuiTab,
    EuiIcon,
    EuiDescriptionList,
    EuiButtonIcon,
    EuiCopy,
} from '@elastic/eui';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getConnectorById } from '../../utils/api';
import OverviewTab from './detail-view/OverviewTab';
import ConfigurationTab from './detail-view/ConfigurationTab';

const CustomIconUrl = {
    'Azure Blob Storage': 'https://img.icons8.com/color/48/azure.png', // Update icon URL if necessary
    Kafka: 'https://img.icons8.com/?size=100&id=fOhLNqGJsUbJ&format=png&color=000000',
    'Google Cloud Storage': 'https://img.icons8.com/?size=100&id=gcp_icon&format=png',
    Outlook: 'https://img.icons8.com/?size=100&id=outlook_icon&format=png',
};

const ConnectorDetailView = () => {
    const { connectorId, deploymentId } = useParams();
    const [connector, setConnector] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('overview');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchConnectorDetails = async () => {
            setLoading(true);
            try {
                const response = await getConnectorById(connectorId);
                setConnector(response.data);
            } catch (error) {
                console.error('Error fetching connector details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchConnectorDetails();
    }, [connectorId]);

    if (loading) {
        return <EuiText>Loading...</EuiText>;
    }

    if (!connector) {
        return <EuiText>Connector details not found.</EuiText>;
    }

    const tabs = [
        { id: 'overview', name: 'Overview', path: `/deployments/view/${deploymentId}/app/connectors/${connectorId}/overview` },
        { id: 'configuration', name: 'Configuration', path: `/deployments/view/${deploymentId}/app/connectors/${connectorId}/configuration` },
    ];
    const onTabClick = (path) => {
        navigate(path);
    };

    return (
        <EuiPage paddingSize="l" style={{ minHeight: '100vh' }}>
            <EuiPageBody>
                {/* Header Section */}
                <EuiPageHeader>
                    <EuiPageHeaderSection>
                        <EuiTitle size="l">
                            <h1>{connector.connector_id || "Connector"}</h1>
                        </EuiTitle>
                        {/* <EuiText color="subdued">
                            <p>Add a description</p>
                        </EuiText> */}
                    </EuiPageHeaderSection>
                    <EuiFlexItem grow={false} style={{ textAlign: 'right' }}>
                        <EuiButton color="primary" fill>Sync</EuiButton>
                    </EuiFlexItem>
                </EuiPageHeader>

                <EuiSpacer size="m" />

                {/* Tabs */}
                <EuiTabs>
                    {tabs.map((tab) => (
                        <EuiTab
                            key={tab.id}
                            isSelected={location.pathname.endsWith(tab.path)}
                            onClick={() => onTabClick(tab.path)}
                        >
                            {tab.name}
                        </EuiTab>
                    ))}
                </EuiTabs>

                <EuiSpacer size="l" />

                <Routes>
                    <Route path="overview" element={<OverviewTab connector={connector} />} />
                    <Route path="configuration" element={<ConfigurationTab connector={connector} />} />

                </Routes>

            </EuiPageBody>
        </EuiPage>
    );
};

export default ConnectorDetailView;