import React from 'react';
import {
    EuiPageTemplate,
    EuiText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiTabs,
    EuiTab,
    EuiSpacer,
    EuiTitle,
    EuiFieldSearch,
    EuiPagination,
    EuiPanel,
    EuiIcon,
    EuiLink,
    EuiCard
} from '@elastic/eui';

const IndexOverview = ({ deploymentId, indexDetails }) => {
    if (!indexDetails) {
        return <EuiText>Error loading index details.</EuiText>;
    }

    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel hasShadow={false} paddingSize="l">
                        <EuiText>
                            <p>Ingestion type</p>
                            <EuiTitle size="m">
                                <h2>{indexDetails?.ingestionType || 'API'}</h2>
                            </EuiTitle>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel hasShadow={false} paddingSize="l">
                        <EuiText>
                            <p>Document count</p>
                            <EuiTitle size="m">
                                <h2>{indexDetails?.docs_count || 0}</h2>
                            </EuiTitle>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="l" />

            <EuiPanel paddingSize="l" hasShadow={false}>
                <EuiTitle size="l">
                    <h2>Getting started with SearchSphere API</h2>
                </EuiTitle>
                <EuiSpacer size="l" />

                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiText>
                            <h4>Select your client</h4>
                            <p>
                                SearchSphere builds and maintains clients in several popular
                                languages. Select
                                your favorite language client or dive into the Console to get
                                started.
                            </p>
                            <EuiLink href="#" target="_blank">
                                SearchSphere clients
                            </EuiLink>
                            <br />
                            <EuiLink href="#" target="_blank">
                                Run API requests in Console
                            </EuiLink>
                        </EuiText>
                    </EuiFlexItem>

                    <EuiSpacer size="l" spacing></EuiSpacer>

                    <EuiFlexItem>
                        <EuiCard
                            icon={<EuiIcon size="xxl" type="console" style={{ marginBottom: "2rem" }} />}
                            title="Try it now in Console"
                            description="With console, you can get started right away with our REST APIs. No installation required."
                            footer={
                                <EuiButton fill href={`/deployments/view/${deploymentId}/app/console`} style={{ marginTop: "2rem" }}>
                                    Try Console now
                                </EuiButton>
                            }
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer size="l" />
            </EuiPanel>
        </>
    );
};

export default IndexOverview;