import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DeploymentDetailHomePage from './pages/DeploymentDetailHomepage';
import DeploymentListPage from './pages/deployments/DeploymentListPage';
import MultistepWelcomeForm from './pages/Welcome';
import { EuiProvider } from '@elastic/eui';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import CreateDeploymentPage from './pages/deployments/CreateDeploymentPage';
import PrivateRoute from './components/PrivateRoute';
import DemoSearchPage from './pages/DemoSearchPage';
import { GlobalToastProvider } from './utils/global-toast-context';


function App() {
  return (
    <EuiProvider>
      <GlobalToastProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to={"/deployments"} />} />
            <Route path="/signup" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path="/demo" element={<DemoSearchPage />} /> */}

            {/* <Route path="/demo" element={
              <PrivateRoute>
                <DemoSearchPage />
              </PrivateRoute>
            } /> */}

            <Route path="/welcome" element={
              <PrivateRoute>
                <MultistepWelcomeForm />
              </PrivateRoute>
            } />

            {/* <Route path="/app/*" element={
            <PrivateRoute>
              <DeploymentDetailHomePage />
            </PrivateRoute>
          } /> */}
            <Route path="/deployments" element={
              <PrivateRoute>
                <DeploymentListPage />
              </PrivateRoute>
            } />
            <Route path="/deployments/create" element={
              <PrivateRoute>
                <CreateDeploymentPage />
              </PrivateRoute>
            } />
            <Route path="deployments/view/:deploymentId/demo" element={
              <PrivateRoute>
                <DemoSearchPage />
              </PrivateRoute>
            } />
            <Route path="deployments/view/:deploymentId/app/*" element={
              <PrivateRoute>
                <DeploymentDetailHomePage />
              </PrivateRoute>
            } />
          </Routes>
        </Router>
      </GlobalToastProvider>
    </EuiProvider>

  );
}

export default App;