import React, { useEffect, useState } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiText,
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiButton,
    EuiSpacer,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSelect,
} from '@elastic/eui';
import { useNavigate, useParams } from 'react-router-dom';
import { cleanCollectedData, createKafkaConnector, createKafkaConnectorSchema, listDeploymentIndexes } from '../../utils/api';
import { getGlobalErrorHandler, getGlobalSuccessToastHandler } from '../../utils/global-toast';
import * as Yup from 'yup';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
    connectorId: Yup.string().required('Connector name is required.'),
    bootstrapServers: Yup.string().required('Bootstrap servers are required.'),
    topic: Yup.string().required('Topic name is required.'),
    numPipelines: Yup.number().required('Number of pipelines is required.').min(1, 'Must be at least 1'),
    username: Yup.string().required('Kafka username is required.'),
    password: Yup.string().required('Kafka password is required.'),
    consumerGroup: Yup.string().notRequired(),
    indexId: Yup.string().notRequired(),
    offsetReset: Yup.string().oneOf(['earliest', 'latest'], 'Invalid offset reset option'),
});

const CreateKafkaConnector = () => {
    const { deploymentId } = useParams();
    const addGlobalError = getGlobalErrorHandler();
    const addGlobalSuccess = getGlobalSuccessToastHandler();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({}); // State to hold form field errors

    const [collectedData, setCollectedData] = useState({
        bootstrapServers: 'pkc-zgp5j7.us-south1.gcp.confluent.cloud:9092',
        topic: 'example-topic',
        consumerGroup: '',
        username: 'L3YIOLWOHEOFHXJI',
        password: 'gwroCWzNxU1PWfoODKN/nbBfcLiiVX4QSxAQQBGca2XBy13F6JMN9+L/gVL1Y7wX',
        connectorId: 'example-connector-id',
        numPipelines: 3,
        offsetReset: 'latest',
        indexId: '5676732c-5348-4754-9c45-0b6a54d1a719',
    });

    const [indices, setIndices] = useState([]);

    useEffect(() => {
        const fetchIndexes = async () => {
            try {
                const response = await listDeploymentIndexes({ deploymentId });
                setIndices(response.data);
            } catch (error) {
                console.error('Error fetching indexes:', error);
            }
        };

        fetchIndexes();
    }, [deploymentId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCollectedData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setErrors({});
            await validationSchema.validate(collectedData, { abortEarly: false });

            const cleanData = cleanCollectedData({
                connector_id: collectedData.connectorId,
                num_pipelines: collectedData.numPipelines,
                kafka_bootstrap_servers: collectedData.bootstrapServers,
                kafka_username: collectedData.username,
                kafka_password: collectedData.password,
                consumer_group_id: collectedData.consumerGroup,
                offset_reset: collectedData.offsetReset,
                topic_name: collectedData.topic,
            });


            console.log({ cleanData, indexId: collectedData.indexId })

            await createKafkaConnector(collectedData.indexId, cleanData);
            addGlobalSuccess('Connector was added successfully.');
            navigate(`/deployments/view/${deploymentId}/app/connectors`);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const fieldErrors = {};
                error.inner.forEach((err) => {
                    fieldErrors[err.path] = err.message;
                });
                setErrors(fieldErrors);
                console.log({ error })
            } else {
                addGlobalError('Failed to create connector.');
                console.error({ error });
            }
        }
    };

    return (
        <EuiPage paddingSize="l" style={{ minHeight: '100vh' }}>
            <EuiPageBody>
                <EuiPageHeader>
                    <EuiPageHeaderSection>
                        <EuiTitle size="l">
                            <h1>New Kafka Connector</h1>
                        </EuiTitle>
                        <EuiText color="subdued">
                            <p>Define a Kafka connector, to index data from a specific topic.
                                <br />
                                Connectors are SearchSphere integrations that write directly to a SearchSphere index.</p>
                        </EuiText>
                    </EuiPageHeaderSection>
                </EuiPageHeader>

                <EuiSpacer size="l" />

                <EuiForm component="form" onSubmit={handleSubmit}>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Connector name"
                                isInvalid={!!errors.connectorId}
                                error={errors.connectorId}
                                helpText="Names should be lowercase and cannot contain spaces or special characters."
                            >
                                <EuiFieldText
                                    name="connectorId"
                                    value={collectedData.connectorId}
                                    onChange={handleChange}
                                    placeholder="Set a name for your connector"
                                    isInvalid={!!errors.connectorId}

                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Index Name"
                                helpText="Which index do you want to ingest data to?"
                            >
                                <EuiSelect
                                    options={indices.map((ind) => ({
                                        value: ind.uuid,
                                        text: ind.index_name,
                                    }))}
                                    value={collectedData.indexId}
                                    onChange={(e) => setCollectedData({ ...collectedData, indexId: e.target.value })}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="l" />

                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Kafka Bootstrap Servers"
                                isInvalid={!!errors.bootstrapServers}
                                error={errors.bootstrapServers}
                                helpText="Comma separated list of Kafka Bootstrap Servers"
                            >
                                <EuiFieldText
                                    name="bootstrapServers"
                                    value={collectedData.bootstrapServers}
                                    onChange={handleChange}
                                    placeholder="example.cloud:9092, example.cloud2"
                                    isInvalid={!!errors.bootstrapServers}

                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Number of Pipelines"
                                isInvalid={!!errors.numPipelines}
                                error={errors.numPipelines}
                                helpText="Number of pipelines SearchSphere should create? Used to index data in parallel"
                            >
                                <EuiFieldText
                                    name="numPipelines"
                                    value={collectedData.numPipelines}
                                    onChange={handleChange}
                                    placeholder="3"
                                    isInvalid={!!errors.numPipelines}

                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiSpacer size="l" />

                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Kafka Username"
                                isInvalid={!!errors.username}
                                error={errors.username}
                                helpText="We encrypt this and store in our Cloud Secret Manager"
                            >
                                <EuiFieldText
                                    name="username"
                                    value={collectedData.username}
                                    onChange={handleChange}
                                    placeholder="Enter your Kafka Username"
                                    isInvalid={!!errors.username}

                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Kafka Password"
                                isInvalid={!!errors.password}
                                error={errors.password}
                                helpText="We encrypt this and store in our Cloud Secret Manager"
                            >
                                <EuiFieldText
                                    name="password"
                                    value={collectedData.password}
                                    onChange={handleChange}
                                    placeholder="Enter your Kafka Password"
                                    type="password"
                                    isInvalid={!!errors.password}

                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="l" />

                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Consumer Group ID"
                                isInvalid={!!errors.consumerGroup}
                                error={errors.consumerGroup}
                                helpText="Optional consumer group id you want SearchSphere to use when connecting to your Kafka Instance."
                            >
                                <EuiFieldText
                                    name="consumerGroup"
                                    value={collectedData.consumerGroup}
                                    isInvalid={!!errors.consumerGroup}
                                    onChange={handleChange}
                                    placeholder="example-consumer-group"
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow label="Offset Reset">
                                <EuiSelect
                                    name="offsetReset"
                                    value={collectedData.offsetReset}
                                    onChange={handleChange}
                                    options={[
                                        { value: "earliest", text: "earliest" },
                                        { value: "latest", text: "latest" },
                                    ]}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="l" />

                    <EuiFormRow label="Topic Name">
                        <EuiFieldText
                            name="topic"
                            value={collectedData.topic}
                            onChange={handleChange}
                            placeholder="Kafka topic name"
                        />
                    </EuiFormRow>

                    <EuiSpacer size="xl" />

                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiButtonEmpty>Back</EuiButtonEmpty>
                        <EuiButton color="primary" type="submit" fill>Create connector</EuiButton>
                    </EuiFlexGroup>
                </EuiForm>
            </EuiPageBody>
        </EuiPage>
    );
};

export default CreateKafkaConnector;