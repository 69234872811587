import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import SplitPane from 'react-split-pane';
import { Decoration, ViewPlugin } from '@codemirror/view';
import { EditorView } from '@codemirror/view';
import ReactJson from 'react-json-view';

const initialConsoleContent = `
# Welcome to the Dev Tools Console!
#
# You can use Console to explore the API.
#
# Create an index
PUT /my-index

# Add a document to my-index
POST /my-index/_doc
{
  "id": "park_rocky-mountain",
  "title": "Rocky Mountain",
  "description": "This portion of the Rockies has ecosystems from over 150 lakes to treeless alpine tundra."
}

# Perform a search in my-index
GET /my-index/_search?q=rocky mountain
`;

const DevToolsConsole = () => {
    const [code, setCode] = useState(initialConsoleContent);
    const [response, setResponse] = useState(null);
    const editorViewRef = useRef(null); // Reference to the CodeMirror editor view

    // Parse the editor content to find requests
    const parseRequests = (code) => {
        const lines = code.split('\n');
        const requests = [];

        lines.forEach((line, index) => {
            if (/^(GET|POST|PUT|DELETE)\s/.test(line)) {
                requests.push({
                    method: line.split(' ')[0],
                    url: line.split(' ')[1],
                    line: index, // Line number
                    body: null, // Body content starts empty
                });
            }
        });

        return requests;
    };

    // Handle sending individual requests
    const executeRequest = async (request) => {
        try {
            const config = {
                method: request.method.toLowerCase(),
                url: request.url,
                data: request.method !== 'get' ? request.body : undefined,
            };

            const res = await axios(config);
            setResponse(res.data);
        } catch (error) {
            setResponse(`Error: ${error.message}`);
        }
    };

    // Create CodeMirror decorations for buttons (shows only when the cursor is on the request line)
    const addSendRequestButtons = (requests, view) => {
        const cursorLine = view.state.doc.lineAt(view.state.selection.main.head).number - 1;
        const widgets = requests
            .filter((request) => request.line === cursorLine) // Show button only for the request on the current cursor line
            .map((request) => {
                const buttonWidget = Decoration.widget({
                    widget: new SendRequestWidget({
                        request,
                        onSend: () => executeRequest(request),
                    }),
                    side: 1,
                });
                const line = view.state.doc.line(request.line + 1);
                return buttonWidget.range(line.to); // Attach button at the end of the line
            });
        return Decoration.set(widgets);
    };

    // Create the SendRequestWidget for embedding the button inside the editor
    class SendRequestWidget {
        constructor({ request, onSend }) {
            this.request = request;
            this.onSend = onSend;
        }

        toDOM() {
            // Create a button element for sending the request
            const button = document.createElement('button');
            button.classList.add('send-request-button');
            button.innerHTML = '▶'; // Play icon
            button.style.cssText = `
                background: none;
                border: none;
                color: #00A3E0;
                cursor: pointer;
                font-size: 14px;
                padding: 0;
                margin-left: 10rem;
                position: relative;
                vertical-align: middle; /* Ensure it stays on the same line */
            `;

            // Create a wrapper div to position the tooltip and button
            const wrapper = document.createElement('div');
            wrapper.style.display = 'inline'; // Ensure it stays inline with the text
            wrapper.style.position = 'relative'; // Position relative for absolute tooltip placement
            wrapper.style.zIndex = '9999'; // Ensure the wrapper overlays everything

            // Tooltip
            const tooltip = document.createElement('span');
            tooltip.classList.add('tooltip-text');
            tooltip.innerText = 'Click to send request';
            tooltip.style.cssText = `
                visibility: hidden;
                background-color: #333;
                color: #fff;
                text-align: center;
                border-radius: 5px;
                padding: 5px;
                position: absolute; /* Anchor it to the wrapper */
                z-index: 99999;
                left: 70%;
                top: -35px;
                transform: translateX(-50%);
                opacity: 0;
                transition: opacity 0.3s;
                font-size: 12px;
                white-space: nowrap;
            `;

            // Button hover event to show the tooltip
            button.addEventListener('mouseover', () => {
                tooltip.style.visibility = 'visible';
                tooltip.style.opacity = '1';
            });

            button.addEventListener('mouseout', () => {
                tooltip.style.visibility = 'hidden';
                tooltip.style.opacity = '0';
            });

            button.addEventListener('click', this.onSend);

            // Append the button and the tooltip to the wrapper
            wrapper.appendChild(button);
            wrapper.appendChild(tooltip);

            return wrapper;
        }

        // Ensure the widget can compare properly
        compare(other) {
            return other.request.method === this.request.method && other.request.url === this.request.url;
        }

        destroy() {
            // Cleanup logic, if necessary
        }

        ignoreEvent() {
            return true;
        }
    }

    // Create decorations for highlighting the current cursor line
    const highlightCurrentLine = ViewPlugin.fromClass(
        class {
            constructor(view) {
                this.decorations = this.updateDeco(view);
            }

            update(update) {
                if (update.selectionSet || update.docChanged) {
                    this.decorations = this.updateDeco(update.view);
                }
            }

            updateDeco(view) {
                const line = view.state.doc.lineAt(view.state.selection.main.head);
                return Decoration.set([Decoration.line({ class: 'highlight-line' }).range(line.from)]);
            }
        },
        { decorations: (v) => v.decorations }
    );

    // Plugin for adding buttons to the editor (only for the current cursor line)
    const requestButtonPlugin = ViewPlugin.fromClass(
        class {
            constructor(view) {
                this.decorations = addSendRequestButtons(parseRequests(view.state.doc.toString()), view);
            }

            update(update) {
                if (update.docChanged || update.selectionSet) {
                    this.decorations = addSendRequestButtons(parseRequests(update.state.doc.toString()), update.view);
                }
            }

            decorations() {
                return this.decorations;
            }
        },
        {
            decorations: (v) => v.decorations,
        }
    );

    return (
        <EuiFlexGroup style={{ height: '100%', backgroundColor: '#1D1E24', width: '100%', position: 'relative', zIndex: 1 }}>
            <EuiFlexItem grow={1} style={{ position: 'relative', height: '100%', zIndex: 1 }}>
                <SplitPane
                    split="vertical"
                    minSize={300}
                    defaultSize="50%"
                    style={{ height: '100%', position: 'relative' }}
                    resizerStyle={{
                        background: '#3a3a3a',
                        width: '10px',
                        cursor: 'col-resize',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    pane1Style={{
                        overflow: 'hidden', position: 'relative', zIndex: 1,
                    }}
                    pane2Style={{ overflow: 'hidden', zIndex: 1 }}
                >
                    {/* Left Panel: CodeMirror Editor */}
                    <div style={{ padding: '0', backgroundColor: '#1D1E24', position: 'relative', zIndex: 1 }}>
                        <CodeMirror
                            value={code}
                            height="calc(100vh - 60px)"
                            theme={oneDark}
                            extensions={[javascript(), requestButtonPlugin, highlightCurrentLine]}
                            onChange={(value) => setCode(value)}
                            basicSetup={{ lineWrapping: true }}
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                    </div>

                    {/* Right Panel: API Response */}
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#292A36',
                            color: '#fff',
                            zIndex: 1,
                        }}
                    >
                        <EuiPanel
                            paddingSize="none"
                            style={{
                                flex: 1,
                                overflowY: 'auto',
                                color: '#fff',
                                zIndex: 1,
                            }}
                        >
                            {/* <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', padding: '10px' }}>
                                {response ? JSON.stringify(response, null, 2) : 'No response yet'}
                            </pre> */}
                            <ReactJson
                                src={response} // The JSON object representing index mappings
                                theme="twilight"
                                displayDataTypes={false}
                                displayObjectSize={true}
                                enableClipboard={true}
                                style={{ padding: '10px' }}
                            />
                        </EuiPanel>
                    </div>
                </SplitPane>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default DevToolsConsole;
