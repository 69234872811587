import React, { useState, useEffect } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiFieldText,
    EuiSelect,
    EuiButton,
    EuiForm,
    EuiFormRow,
    EuiLink,
    EuiHeader,
    EuiHeaderSection,
    EuiHeaderSectionItem,
    EuiText,
    EuiAvatar
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import { fetchVersions, fetchCloudProvidersWithRegion, createDeployment } from '../../utils/api';
import HeaderWithSearch from '../../components/HeaderWithSearch';

const CreateDeploymentPage = () => {
    const navigate = useNavigate();
    const [deploymentName, setDeploymentName] = useState('My deployment');
    const [cloudProvider, setCloudProvider] = useState('');
    const [region, setRegion] = useState('');
    const [version, setVersion] = useState('');
    const [versionOptions, setVersionOptions] = useState([]);
    const [cloudProviderOptions, setCloudProviderOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [providers, setProviders] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getVersions = async () => {
            try {
                const response = await fetchVersions();
                const versions = response.data.map(version => ({
                    value: version.uuid,
                    text: version.version_number
                }));
                setVersionOptions(versions);
                setVersion(versions[0].value); // Set default version
            } catch (error) {
                console.error('Error fetching versions:', error);
            }
        };

        const getCloudProvidersWithRegion = async () => {
            try {
                const response = await fetchCloudProvidersWithRegion();
                const providers = response.data.reduce((acc, item) => {
                    if (!acc[item.name]) {
                        acc[item.name] = [];
                    }
                    acc[item.name].push({ value: item.uuid, text: item.region });
                    return acc;
                }, {});

                const providerOptions = Object.keys(providers).map(provider => ({
                    value: provider,
                    text: provider
                }));

                setCloudProviderOptions(providerOptions);
                setProviders(providers);
                setCloudProvider(providerOptions[0].value); // Set default provider
                setRegionOptions(providers[providerOptions[0].value]);
                setRegion(providers[providerOptions[0].value][0].value); // Set default region
            } catch (error) {
                console.error('Error fetching cloud providers and regions:', error);
            }
        };

        getVersions();
        getCloudProvidersWithRegion();
    }, []);

    const handleCloudProviderChange = (e) => {
        const selectedProvider = e.target.value;
        setCloudProvider(selectedProvider);
        setRegionOptions(providers[selectedProvider]);
        setRegion(providers[selectedProvider][0].value); // Set default region for selected provider
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const deploymentData = {
            name: deploymentName,
            version_id: version,
            cloud_provider_id: region
        };

        try {
            await createDeployment(deploymentData);
            navigate('/deployments');
        } catch (err) {
            console.error('Error creating deployment:', err);
            setError('Failed to create deployment. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <EuiPage paddingSize="l">
            <HeaderWithSearch showSearch={false} />

            <EuiPageBody>
                <EuiSpacer size="xxl" />
                <EuiFlexGroup direction="column" alignItems="center">
                    <EuiFlexItem grow={false} style={{ width: '500px' }}>
                        <EuiPanel paddingSize="l">
                            <EuiTitle size="l">
                                <h1>Create your first deployment</h1>
                            </EuiTitle>
                            <EuiSpacer size="m" />
                            <EuiText>
                                A deployment includes Elasticsearch, Kibana, and other Elastic Stack features, allowing you to store, search, and analyze your data.
                            </EuiText>
                            <EuiSpacer size="l" />
                            <EuiForm component="form" onSubmit={handleSubmit}>
                                <EuiFormRow label="Name">
                                    <EuiFieldText
                                        value={deploymentName}
                                        onChange={(e) => setDeploymentName(e.target.value)}
                                        placeholder="Deployment Name"
                                    />
                                </EuiFormRow>
                                <EuiSpacer size="m" />
                                <EuiTitle size="m">
                                    <h2>Settings</h2>
                                </EuiTitle>
                                <EuiSpacer size="m" />
                                <EuiFormRow label="Cloud provider">
                                    <EuiSelect
                                        options={cloudProviderOptions}
                                        value={cloudProvider}
                                        onChange={handleCloudProviderChange}
                                    />
                                </EuiFormRow>
                                <EuiSpacer size="m" />
                                <EuiFormRow label="Region">
                                    <EuiSelect
                                        options={regionOptions}
                                        value={region}
                                        onChange={(e) => setRegion(e.target.value)}
                                    />
                                </EuiFormRow>
                            
                                <EuiSpacer size="m" />
                                <EuiFormRow label="Version">
                                    <EuiSelect
                                        options={versionOptions}
                                        value={version}
                                        onChange={(e) => setVersion(e.target.value)}
                                    />
                                </EuiFormRow>
                                <EuiSpacer size="m" />
                                {error && (
                                    <>
                                        <EuiText color="danger">{error}</EuiText>
                                        <EuiSpacer size="m" />
                                    </>
                                )}
                                <EuiButton type="submit" fill style={{ width: '100%' }} isLoading={loading}>
                                    Create deployment
                                </EuiButton>
                            </EuiForm>
                            <EuiSpacer size="m" />
                            <EuiLink href="#">Show configuration</EuiLink>
                            <EuiSpacer size="m" />
                            <EuiLink onClick={() => navigate(-1)}>Go back</EuiLink>
                        </EuiPanel>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPageBody>
        </EuiPage>
    );
};

export default CreateDeploymentPage;