import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams, Navigate } from 'react-router-dom';
import {
  EuiHeader,
  EuiAvatar,
  EuiPageTemplate,
  EuiPageBody,
  EuiPageSection,
} from '@elastic/eui';
import HeaderWithSearch from '../components/HeaderWithSearch';
import SidebarNavigation from '../components/SidebarNavigation';
import IndexManagementPage from '../components/indices/IndexManagementPage';
import CreateIndexWorkflow from '../components/indices/CreateIndexPage';
import IndexDetailView from '../components/indices/IndexDetailView';
import DevToolsConsole from '../components/DevToolsConsole';
import CreateConnectorPage from '../components/connectors/CreateConnectorPage';
import ConnectorPage from '../components/connectors/ConnectorPage';
import ConnectorDetailView from '../components/connectors/ConnectorDetailView';
import CreateKafkaConnector from '../components/connectors/CreateKafkaConnector';

function DeploymentDetailHomePage() {

  const { deploymentId } = useParams();
  // Add theme detection hook
  const [isDark, setIsDark] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  // Listen for theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handler = (e) => setIsDark(e.matches);

    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, []);


  const breadcrumbs = [
    {
      text: 'Deployments',
      href: '/deployments',
    },
    {
      text: 'Search',
      href: `/deployments/view/${deploymentId}/app/indices`,
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ];

  return (
    <div className="App" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <HeaderWithSearch showSearch={true} />

      <EuiHeader
        position="fixed"
        sections={[
          {
            items: [
              <EuiAvatar type="space" name="Default Space" size="s" />,
            ],
            breadcrumbs: breadcrumbs,
          },
        ]}
      />

      {/* Main layout: Sidebar and content */}
      <div style={{ display: 'flex', height: '100%', marginTop: '48px' }}>
        {/* Sidebar (left) */}
        <div style={{
          height: 'calc(100vh - 48px)',
          position: 'fixed',
          overflowY: 'auto',
          paddingTop: '56px',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }}>
          <SidebarNavigation />
        </div>

        {/* Main content (right) */}
        <div style={{
          marginLeft: '250px',
          flexGrow: 1,
          overflowY: 'auto',
          padding: '20px',
          height: 'calc(100vh - 48px)'
        }}>
          <EuiPageTemplate.Header />

          <EuiPageBody component="div">
            <Routes>
              <Route path="console" element={
                <EuiPageSection style={{ paddingLeft: "0", paddingRight: "0" }}>
                  <DevToolsConsole />
                </EuiPageSection>
              } />
              <Route path="connectors" element={
                <EuiPageSection style={{ paddingLeft: "0", paddingRight: "0" }}>
                  <ConnectorPage />
                </EuiPageSection>
              } />
              <Route path="connectors/create" element={
                <EuiPageSection style={{ paddingLeft: "0", paddingRight: "0" }}>
                  <CreateConnectorPage />
                </EuiPageSection>
              } />

              <Route path="connectors/create/kafka" element={
                <EuiPageSection style={{ paddingLeft: "0", paddingRight: "0" }}>
                  <CreateKafkaConnector />
                </EuiPageSection>
              } />
              <Route path="connectors/:connectorId/*" element={
                <EuiPageSection style={{ paddingLeft: "0", paddingRight: "0" }}>
                  <ConnectorDetailView />
                </EuiPageSection>
              } />




              <Route path="indices" element={
                <EuiPageSection>
                  <IndexManagementPage />
                </EuiPageSection>
              } />
              <Route path="indices/create" element={
                <EuiPageSection>
                  <CreateIndexWorkflow />
                </EuiPageSection>
              } />
              <Route path="indices/details/:indexId/*" element={
                <EuiPageSection>
                  <IndexDetailViewWrapper />
                </EuiPageSection>
              } />
              <Route path="/" element={<Navigate to="indices" />} />
            </Routes>
          </EuiPageBody>
        </div>
      </div>
    </div>
  );
}

const IndexDetailViewWrapper = () => {
  const { indexId, deploymentId } = useParams();
  return <IndexDetailView indexId={indexId} deploymentId={deploymentId} />;
};

export default DeploymentDetailHomePage;