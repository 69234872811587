import React, { useState, useEffect } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
    EuiFieldSearch,
    EuiButton,
    EuiSpacer,
    EuiEmptyPrompt
} from '@elastic/eui';
import IndexListTable from './IndexListTable';
import { useNavigate, useParams } from 'react-router-dom';
import { listDeploymentIndexes } from '../../utils/api';

const IndexManagementPage = () => {
    const { deploymentId } = useParams();

    const [showHiddenIndices, setShowHiddenIndices] = useState(false);
    const [showCrawlerIndices, setShowCrawlerIndices] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [indices, setIndices] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIndexes = async () => {
            try {
                const response = await listDeploymentIndexes({deploymentId});
                setIndices(response.data.indexes);
            } catch (error) {
                console.error('Error fetching indexes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchIndexes();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            {/* Overview Cards */}
            {indices.length > 0 && (<EuiFlexGroup gutterSize="l" wrap>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText size="s">
                            <p>Connected ingest methods</p>
                            <h2>0</h2>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText size="s">
                            <p>Incomplete ingest methods</p>
                            <h2>0</h2>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText size="s">
                            <p>Running syncs</p>
                            <h2>0</h2>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText size="s">
                            <p>Idle syncs</p>
                            <h2>0</h2>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText size="s">
                            <p>Orphaned syncs</p>
                            <h2>0</h2>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText size="s">
                            <p>Sync errors</p>
                            <h2>0</h2>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
            )}


            {/* Toolbar */}
            {indices.length > 0 && <EuiSpacer size="l" />
            }
            {
                indices.length > 0 && <EuiFlexGroup gutterSize="xl" alignItems="center" justifyContent='spaceBetween'>
                    <EuiFlexItem grow={3}>
                        <EuiFieldSearch
                            placeholder="Filter Elasticsearch indices"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            fullWidth
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={1} wrap="true">
                        <EuiButton onClick={() => navigate(`/deployments/view/${deploymentId}/app/indices/create`)} fill iconType="plus">
                            Create Index
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            }

            <EuiSpacer size="l" />
            {/* Indices Table */}
            <EuiPanel paddingSize="m">
                {loading ? (
                    <EuiText>Loading...</EuiText>
                ) : indices.length === 0 ? (
                    <EuiEmptyPrompt
                        iconType="managementApp"
                        title={<h2>No indices found</h2>}
                        body={
                            <p>
                                Create your first index to get started with Elasticsearch.
                            </p>
                        }
                        actions={
                            <EuiButton onClick={() => navigate(`/deployments/view/${deploymentId}/app/indices/create`)} fill iconType="plus">
                                Create Index
                            </EuiButton>
                        }
                    />
                ) : (
                    <IndexListTable indices={indices} deploymentId={deploymentId} />
                )}
            </EuiPanel>
        </div>
    );
};

export default IndexManagementPage;