import React, { useState } from 'react';
import {
    EuiFieldText,
    EuiFieldPassword,
    EuiButton,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiText,
    EuiLink,
    EuiHorizontalRule,
    EuiPageTemplate,
    EuiPageBody
} from '@elastic/eui';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { registerUser } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../utils/user-context';

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { dispatch } = useUserContext();
    const navigate = useNavigate();

    const handleSignup = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await registerUser({ email, password });
            if (response.user) {
                dispatch({ type: 'SET_USER', payload: response.user });
                navigate('/deployments');
            }
        } catch (err) {
            console.log(err);
            setError('Registration failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            console.log('Google login success:', response);
            try {
                const token = response.credential;
                // Send the token to your backend to verify and create a user session
                const userResponse = await registerUser({ token, provider: 'google' });
                if (userResponse.user) {
                    dispatch({ type: 'SET_USER', payload: userResponse.user });
                    navigate('/deployments');
                }
            } catch (err) {
                console.log(err);
                setError('Google login failed. Please try again.');
            }
        },
        onError: (error) => {
            console.log('Google login failure:', error);
            setError('Google login failed. Please try again.');
        }
    });

    return (
        <EuiPageTemplate restrictWidth={true} template="empty">
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' style={{ padding: '0.5rem 0' }}>
                <EuiFlexItem>
                    <EuiText>
                        <strong>SearchSphere</strong>
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ padding: '0.5rem' }}>
                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <EuiText>
                                <strong>Already have an account?</strong>
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton onClick={() => navigate('/login')}>
                                Log in
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiPageBody>
                <EuiForm component="form" onSubmit={(e) => { e.preventDefault(); handleSignup(); }}>
                    <EuiFlexGroup direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                        <EuiFlexItem grow={false} style={{ width: '300px' }}>
                            <EuiText textAlign="center">
                                <h2>Sign up for a free trial</h2>
                            </EuiText>
                            <EuiSpacer size="l" />

                            <EuiFormRow label="Email" isInvalid={!!error} error={error}>
                                <EuiFieldText
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    aria-label="Email"
                                />
                            </EuiFormRow>

                            <EuiSpacer size="m" />

                            <EuiFormRow label="Password" isInvalid={!!error} error={error}>
                                <EuiFieldPassword
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    aria-label="Password"
                                    type="dual"
                                />
                            </EuiFormRow>

                            <EuiSpacer size="l" />

                            <EuiButton
                                type="submit"
                                fill
                                isLoading={loading}
                                disabled={!email || !password}
                                style={{ width: '100%' }}
                            >
                                Sign up with email
                            </EuiButton>

                            <EuiSpacer size="l" />

                            <GoogleLoginButton navigate={navigate} dispatch={dispatch} setError={setError} />

                            <EuiSpacer size="m" />

                            <EuiButton
                                iconType="logoMicrosoft"
                                onClick={() => { }}
                                style={{ width: '100%' }}
                            >
                                Sign up with Microsoft
                            </EuiButton>

                            <EuiSpacer size="l" />

                            <EuiText size="s" textAlign="center">
                                <p>
                                    By signing up, you acknowledge that you've read and agree to our <EuiLink href="#">Terms of Service</EuiLink> and <EuiLink href="#">Privacy Statement</EuiLink>.
                                </p>
                            </EuiText>

                            <EuiHorizontalRule size="full" />

                            <EuiText textAlign="center" size="s">
                                <p>OR</p>
                            </EuiText>

                            <EuiText textAlign="center" size="s">
                                <p>Subscribe via a cloud marketplace for simplified billing</p>
                            </EuiText>

                            <EuiSpacer size="m" />

                            <EuiFlexGroup justifyContent="center" gutterSize="s">
                                <EuiFlexItem grow={false}>
                                    <EuiButton iconType="logoAWS" onClick={() => { }}>
                                        Amazon Web Services
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton iconType="logoGCP" onClick={() => { }}>
                                        Google Cloud
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton iconType="logoAzure" onClick={() => { }}>
                                        Microsoft Azure
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiForm>
            </EuiPageBody>
        </EuiPageTemplate>
    );
};


const GoogleLoginButton = ({ navigate, dispatch, setError }) => {
    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            console.log('Google login success:', response);
            try {
                const token = response.credential;
                const userResponse = await registerUser({ token, provider: 'google' });
                if (userResponse.user) {
                    dispatch({ type: 'SET_USER', payload: userResponse.user });
                    navigate('/deployments');
                }
            } catch (err) {
                console.log(err);
                setError('Google login failed. Please try again.');
            }
        },
        onError: (error) => {
            console.log('Google login failure:', error);
            setError('Google login failed. Please try again.');
        }
    });

    return (
        <EuiButton
            iconType="logoGoogleG"
            onClick={handleGoogleLogin}
            style={{ width: '100%' }}
        >
            Sign up with Google
        </EuiButton>
    );
};


export default RegisterPage;