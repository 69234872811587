import React from 'react';
import ReactJson from 'react-json-view';
import { EuiPanel, EuiSpacer, EuiText } from '@elastic/eui';

const IndexMappings = ({ indexMappingsData }) => {
    return (
        <EuiPanel paddingSize="l">
            <EuiText>
                <h2>Index Mappings</h2>
            </EuiText>
            <EuiSpacer size="m" />
            <ReactJson
                src={indexMappingsData} // The JSON object representing index mappings
                theme="twilight"
                displayDataTypes={true}
                displayObjectSize={true}
                enableClipboard={true}
                name={null}
                style={{ padding: '10px' }}
            />
        </EuiPanel>
    );
};

export default IndexMappings;
