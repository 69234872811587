import React, { useState, useRef } from 'react';
import {
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiButtonEmpty,
    EuiFormRow,
    EuiForm,
    EuiComboBox,
    EuiDatePicker,
    EuiDatePickerRange
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { deleteDocsFromIndex } from '../../utils/api';

const CreateDeleteRequest = ({ deploymentId, indexId, fieldNames }) => {
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    // const [searchFields, setSearchFields] = useState([]);
    // const [searchFieldOptions, setSearchFieldOptions] = useState(fieldNames.map(fieldName => ({ label: fieldName, value: fieldName })));
    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const inputRef = useRef(null);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());

    // const handleSearchFieldsChange = (selectedOptions) => {
    //     setSearchFields(selectedOptions);
    // };

    // const handleCreateOption = (searchValue, flattenedOptions) => {
    //     const newOption = {
    //         label: searchValue,
    //         value: searchValue,
    //     };

    //     // Check if the new option already exists
    //     if (!flattenedOptions.find(option => option.label === searchValue)) {
    //         setSearchFieldOptions([...searchFieldOptions, newOption]);
    //     }

    //     setSearchFields([...searchFields, newOption]);
    // };

    const handleInputChange = (e) => {
        const inputText = e.target.value;
        setQuery(inputText);

        const currentWord = inputText.split(' ').pop(); // Get the current word for suggestions

        // If the current word matches a field prefix, show suggestions
        if (currentWord.length > 0) {
            const filteredSuggestions = fieldNames.filter((field) =>
                field.toLowerCase().startsWith(currentWord.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
            setHighlightedIndex(-1); // Reset highlighted index on new input
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const words = query.split(' ');
        words.pop(); // Remove the current word being typed
        const newQuery = `${words.join(' ')} ${suggestion}: `;
        setQuery(newQuery);
        setSuggestions([]); // Hide suggestions after selection
        inputRef.current.focus(); // Focus back to the input field
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setHighlightedIndex((prev) =>
                prev === suggestions.length - 1 ? 0 : prev + 1
            );
        } else if (e.key === 'ArrowUp') {
            setHighlightedIndex((prev) =>
                prev === 0 ? suggestions.length - 1 : prev - 1
            );
        } else if (e.key === 'Enter' && highlightedIndex !== -1) {
            // Select the currently highlighted suggestion
            handleSuggestionClick(suggestions[highlightedIndex]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newRequest = {
            query,
            // search_fields: searchFields.map(option => option.value) || [],
            start_timestamp: startDate.unix(),
            end_timestamp: endDate.unix(),
        };

        try {
            // Send the new request to the server
            await deleteDocsFromIndex(indexId, newRequest);
            console.log('New Delete Request:', newRequest);

            const link = `/deployments/view/${deploymentId}/app/indices/details/${indexId}/delete-requests`;
            navigate(link); // Navigate back to the delete requests list
        } catch (error) {
            console.error('Failed to delete documents:', error);
        }
    };

    return (
        <EuiPanel paddingSize="l">
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                        iconType="arrowLeft"
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </EuiButtonEmpty>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="l" />
            <EuiForm component="form" onSubmit={handleSubmit}>
                <EuiFormRow label="Query" helpText="Enter the query for the delete request">
                    <div style={{ position: 'relative' }}>
                        <EuiFieldText
                            inputRef={inputRef}
                            value={query}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            placeholder={`_id:"unique id" AND field:"value2"`}
                            aria-label="Query"
                            fullWidth
                        />
                        {suggestions.length > 0 && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    width: '100%',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                    zIndex: 10,
                                    backgroundColor: 'white', // Ensures suggestions are visible
                                }}
                            >
                                {suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        style={{
                                            padding: '8px',
                                            cursor: 'pointer',
                                            backgroundColor:
                                                highlightedIndex === index ? '#0073e6' : "#fff",
                                            color: highlightedIndex === index ? 'white' : 'black',
                                            borderBottom: '1px solid #eee',
                                        }}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </EuiFormRow>
                {/* <EuiFormRow label="Search Fields" helpText="Select the search fields">
                    <EuiComboBox
                        placeholder="Select search fields"
                        options={searchFieldOptions}
                        selectedOptions={searchFields}
                        onChange={handleSearchFieldsChange}
                        onCreateOption={handleCreateOption}
                        aria-label="Search Fields"
                    />
                </EuiFormRow> */}
                <EuiFormRow label="Date Range" helpText="Select the start and end date">
                    <EuiDatePickerRange
                        startDateControl={
                            <EuiDatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                startDate={startDate}
                                endDate={endDate}
                                showTimeSelect
                                aria-label="Start date"
                            />
                        }
                        endDateControl={
                            <EuiDatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                startDate={startDate}
                                endDate={endDate}
                                showTimeSelect
                                aria-label="End date"
                            />
                        }
                    />
                </EuiFormRow>
                <EuiSpacer size="xl" />
                <EuiFlexGroup justifyContent="flexStart">
                    <EuiFlexItem grow={false}>
                        <EuiButton type="submit" fill>
                            Create Delete Request
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiForm>
        </EuiPanel>
    );
};

export default CreateDeleteRequest;