import React from 'react';
import ReactDOM from 'react-dom/client';

import '@elastic/eui/dist/eui_theme_dark.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import createCache from '@emotion/cache';
import { UserProvider } from './utils/user-context';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import '@elastic/eui/dist/eui_theme_light.css';

// Alternatively, for Dark theme:
// import '@elastic/eui/dist/eui_theme_dark.css';

const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY_PROJECT_ID;

// Inject Microsoft Clarity script
(function(c, l, a, r, i, t, y) {
  c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments) };
  t = l.createElement(r);
  t.async = 1;
  t.src = "https://www.clarity.ms/tag/" + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
})(window, document, "clarity", "script", CLARITY_PROJECT_ID);


// Create a custom cache
const cache = createCache({
  key: 'eui',
  container: document.querySelector('meta[name="emotion-styles"]'),
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <UserProvider>
        <App />
      </UserProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
