import React, { useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiTextArea,
    EuiButton,
    EuiPanel,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiProgress,
    EuiButtonEmpty,
    EuiSwitch,
    EuiText,
    EuiSelect,
    EuiFieldNumber,
    EuiComboBox,
    EuiTitle,
    EuiButtonIcon, // Added import
    EuiToolTip,
    EuiOverlayMask,
    EuiConfirmModal
} from '@elastic/eui';

import ReactJson from 'react-json-view';  // JSON editor package
import { cleanCollectedData, createIndex, createIndexSchema } from '../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

const CreateIndexWorkflow = () => {
    const { deploymentId } = useParams();

    const [step, setStep] = useState(1);
    const [indexName, setIndexName] = useState('');
    const [jsonSample, setJsonSample] = useState('');
    const [parsedFields, setParsedFields] = useState([]);
    const [search_settings, setSearchSettings] = useState([]);
    const [isTimeBasedIndex, setIsTimeBasedIndex] = useState(false); // Time-based index switch
    const [timestampField, setTimestampField] = useState(''); // Selected timestamp field
    const [enforceDeduplication, setEnforceDeduplication] = useState(false); // Deduplication enforcement switch
    const [deduplicationFields, setDeduplicationFields] = useState([]); // Deduplication fields
    const [enforceRetention, setEnforceRetention] = useState(false); // Retention policy switch
    const [retentionDays, setRetentionDays] = useState(''); // Retention policy days
    const [advancedConfig, setAdvancedConfig] = useState(true); // Advanced configuration switch
    const [tokenizer, setTokenizer] = useState('standard'); // Tokenizer selection
    const [tagFields, setTagFields] = useState([]); // Tag fields
    const [partitionKey, setPartitionKey] = useState(""); // Partition key
    const [deduplicationError, setDeduplicationError] = useState(''); // Deduplication field error message
    const [parsedJson, setParsedJson] = useState(null); // Parsed and formatted JSON
    const [jsonError, setJsonError] = useState(''); // Error message for invalid JSON
    const [useDynamicMapping, setUseDynamicMapping] = useState(true); // Use dynamic mapping
    const [indexNameError, setIndexNameError] = useState(''); // Error message for invalid index name
    const [validationErrors, setValidationErrors] = useState({});

    const navigate = useNavigate();


    let totalSteps = advancedConfig ? 6 : 5; // If advanced config is enabled, add an extra step
    totalSteps = useDynamicMapping ? 4 : totalSteps;


    const [collectedData, setCollectedData] = useState({});

    const [isDeleteFieldModalVisible, setIsDeleteFieldModalVisible] = useState(false);
    const [fieldToRemove, setFieldToRemove] = useState(null);


    const showModal = (field_name) => {
        setFieldToRemove(field_name);
        setIsDeleteFieldModalVisible(true);
    };

    const closeModal = () => {
        setIsDeleteFieldModalVisible(false);
        setFieldToRemove(null);
    };

    const confirmRemove = () => {
        // Handle the removal logic here
        console.log(`Removing field: ${fieldToRemove}`);
        setParsedFields((prevFields) => prevFields.filter(field => field.field_name !== fieldToRemove));

        closeModal();
    };



    // Proceed to next step
    const onNext = () => {
        console.log({ step })

        if (step === 1) {
            if (!useDynamicMapping) {
                parseJsonFields();
            } else {
                setStep(step + 2);
                return;
            }
        }

        if (step === 4 && useDynamicMapping) {
            setStep(step + 2); // Skip the deduplication step
            return;
        }


        if ((!useDynamicMapping && !jsonError && parsedJson) || (useDynamicMapping)) {
            console.log({ step, ts: totalSteps })
            if (step === totalSteps - 1) {
                const data = {
                    index_name: indexName,
                    search_settings,
                    timestamp_field: timestampField,
                    enforce_deduplication: enforceDeduplication,
                    deduplication_fields: deduplicationFields.map(field => field.label),
                    enforce_retention: enforceRetention,
                    retention_days: retentionDays,
                    advanced_config: advancedConfig,
                    tag_fields: tagFields.map(field => field.label),
                    partition_key: partitionKey,
                    use_dynamic_mapping: useDynamicMapping,
                    deployment_id: deploymentId
                };

                setCollectedData(data);
            }

            console.log('Final search Data:', search_settings);
            setStep(step + 1);
        }

        if ((useDynamicMapping && !jsonError)) {
            console.log({ step, ts: totalSteps })
            if (step === totalSteps - 1) {
                const data = {
                    index_name: indexName,
                    search_settings,
                    timestamp_field: timestampField,
                    enforce_deduplication: enforceDeduplication,
                    deduplication_fields: deduplicationFields.map(field => field.label),
                    enforce_retention: enforceRetention,
                    retention_days: retentionDays,
                    advanced_config: advancedConfig,
                    // tokenizer,
                    tag_fields: tagFields.map(field => field.label),
                    partition_key: partitionKey,
                    use_dynamic_mapping: useDynamicMapping,
                    deployment_id: deploymentId

                };

                setCollectedData(data);
            }
        }


    };

    // Go back to previous step
    const onBack = () => {
        if (step > 1) {
            if (useDynamicMapping && (step === 3 || step === 6)) {
                setStep(step - 2); // Skip the manual mapping step
            } else {
                setStep(step - 1);
            }
        }
    };

    // Parse JSON Sample to extract fields
    const parseJsonFields = () => {
        try {
            const parsedJson = JSON.parse(jsonSample);
            const fields = Object.keys(parsedJson || parsedJson); // Get keys from the first object
            const detectedFields = fields.map((field) => {
                const field_type = isNaN(Date.parse(parsedJson[field])) ? typeof parsedJson[field] : 'timestamp';
                return {
                    field_name: field,
                    field_type,
                    allow_phrase_queries: false,
                    sortable: false,
                    tokenizer: "raw",
                };
            });
            setParsedFields(detectedFields);
            setSearchSettings(detectedFields);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    };

    // Handle Search Settings change
    const updateFieldSetting = (fieldIndex, settingType, value) => {
        const updatedSettings = [...search_settings];
        updatedSettings[fieldIndex][settingType] = value;
        setSearchSettings(updatedSettings);
    };

    // Handle deduplication field selection (limit to 3)
    const onDeduplicationFieldsChange = (selectedFields) => {
        if (selectedFields.length <= 3) {
            setDeduplicationFields(selectedFields);
            setDeduplicationError(''); // Clear error message if selection is valid
        } else {
            setDeduplicationError('You can select up to 3 fields for deduplication.');
        }
    };

    // Handle JSON changes from the editor
    const handleJsonEdit = (edit) => {
        setCollectedData(edit.updated_src);
    };

    // Handle JSON input changes
    const handleJsonChange = (e) => {
        const input = e.target.value;

        setJsonSample(input);
        try {
            const parsedJson = JSON.parse(input);
            const formattedJson = JSON.stringify(parsedJson, null, 2);

            setParsedJson(parsedJson);
            setJsonError('');
        } catch (error) {
            setParsedJson(null);
            setJsonError('Invalid JSON format');
        }
    };

    const handleIndexNameChange = (e) => {
        const value = e.target.value;
        setIndexName(value);
        const regex = /^[a-zA-Z][a-zA-Z0-9-_\\.]{2,254}$/;
        if (!regex.test(value)) {
            setIndexNameError('Index name must start with a letter and can contain letters, numbers, hyphens, underscores, and periods. It must be between 3 and 255 characters long.');
        } else {
            setIndexNameError('');
        }
    };


    // Submit form data
    const onSubmit = async (e) => {
        e.preventDefault();
        console.log('Final Collected Data:', collectedData);

        try {
            // await createIndexSchema.validate(collectedData);
            const cleanData = cleanCollectedData(collectedData);
            await createIndex(cleanData);

            navigate(`/deployments/view/${deploymentId}/app/indices`);
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const errors = error.inner.reduce((acc, err) => {
                    acc[err.path] = err.message;
                    return acc;
                }, {});
                setValidationErrors(errors);
                console.log({ errors })
            } else {
                console.error('Error creating index:', error);
            }
        }
        // Here, pass `collectedData` to the backend or LLM for index configuration generation
    };
    // Tokenizer options
    const tokenizerOptions = [
        { value: 'no', text: 'Raw (No processing, filters tokens > 255 bytes)' },
        { value: 'standard', text: 'Standard (Splits by whitespace/punctuation, converts to lowercase, filters > 255 bytes)' },
        { value: 'englist stem', text: 'English Stemmer (Default + stemming, filters > 255 bytes)' },
        { value: 'chinese stem', text: 'Chinese (Handles CJK characters, filters > 255 bytes)' },
        { value: 'lowercase', text: 'Lowercase (Converts to lowercase only, no tokenization)' },
    ];
    // Field Type options
    const fieldTypeOptions = [
        { value: 'string', text: 'String' },
        { value: 'number', text: 'Number' },
        { value: 'boolean', text: 'Boolean' },
        { value: 'timestamp', text: 'Timestamp' },
        { value: 'object', text: 'Object' },
        { value: 'array', text: 'Array' },
    ];

    return (
        <EuiPanel paddingSize="l">
            <EuiForm component="form" onSubmit={onSubmit}>
                {/* Step Progress Indicator */}
                <EuiProgress value={step} max={totalSteps} size="xs" color="primary" />
                <EuiSpacer size="l" />

                {/* Step 1: Define Index Name and Provide JSON Sample */}
                {step === 1 && (
                    <EuiFlexGroup direction="column" gutterSize="l">
                        <EuiText>
                            <h2>Configure Index & Mappings</h2>
                        </EuiText>
                        <EuiSpacer size="l" />

                        <EuiFlexItem>
                            <EuiFormRow label="Index Name"
                                helpText={!indexNameError && "Enter the name of the index."}
                                isInvalid={!!indexNameError}
                                error={indexNameError}
                            >
                                <EuiFieldText
                                    placeholder="Index Name"
                                    value={indexName}
                                    onChange={handleIndexNameChange}
                                    required
                                />
                            </EuiFormRow>
                        </EuiFlexItem>


                        {/* Switch between raw JSON and formatted view */}
                        <EuiFlexItem>
                            <EuiFormRow label="Use Dynamic Mapping" helpText="Do you want to define index mapping or use dynamic mapping?">
                                <EuiSwitch
                                    label={useDynamicMapping ? 'Use Dynamic Mapping' : 'Define mapping'}
                                    checked={useDynamicMapping}
                                    onChange={(e) => setUseDynamicMapping(e.target.checked)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>

                        {
                            !useDynamicMapping && (
                                <EuiFlexItem style={{ width: "100%" }}>
                                    <EuiFlexGroup style={{ width: "100%" }}>
                                        <EuiFlexItem style={{ height: '400px', width: "50%" }}>
                                            <EuiFormRow
                                                label="Raw Input"
                                                isInvalid={!!jsonError}
                                                error={jsonError}
                                                helpText="Enter valid JSON format."
                                            >
                                                <EuiTextArea
                                                    style={{ height: '400px' }}
                                                    placeholder="Paste your JSON sample here, to auto generate a mapping"
                                                    value={jsonSample}
                                                    onChange={handleJsonChange}
                                                    rows={8}
                                                    isInvalid={!!jsonError} // Highlights the text area if there is an error
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>
                                        <EuiFlexItem style={{ height: '400px', width: "50%" }}>
                                            <EuiFormRow label="Formatted View">
                                                <ReactJson
                                                    src={parsedJson || {}}
                                                    theme="twilight"
                                                    style={{ padding: '10px', height: '400px' }}
                                                    displayDataTypes={false}
                                                    displayObjectSize={true}
                                                    enableClipboard={true}
                                                    name={null}
                                                    onEdit={(edit) => {
                                                        setParsedJson(edit.updated_src);
                                                        setJsonSample(JSON.stringify(edit.updated_src, null, 2));
                                                    }}
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            )
                        }

                        {/* Raw JSON input and formatted view side by side */}
                        <EuiSpacer size="l" />
                        <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiButtonEmpty size="s" onClick={onBack}>Back</EuiButtonEmpty>
                            <EuiButton size='s' onClick={() => { onNext(); }} fill iconType="arrowRight">
                                Next
                            </EuiButton>
                        </EuiFlexGroup>

                    </EuiFlexGroup>
                )}

                {/* Step 2: Search and Storage Settings */}
                {step === 2 && (
                    <EuiFlexGroup direction="column" gutterSize="xl">
                        <EuiText>
                            <h2>Search and Storage Settings</h2>
                            <p>Configure how your fields are searchable and stored within the system.</p>
                        </EuiText>
                        <EuiSpacer size="m" />

                        {parsedFields.map((field, index) => (
                            <EuiPanel key={field.field_name} paddingSize="m" hasShadow={false} hasBorder style={{ marginBottom: '0.1rem' }}>
                                <EuiFlexGroup direction="column" gutterSize="l">
                                    <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                                        <EuiTitle size="s">
                                            <h3>{field.field_name}</h3>
                                        </EuiTitle>
                                        <>
                                            <EuiButtonIcon
                                                iconType="trash"
                                                color="danger"
                                                aria-label={`Remove ${field.field_name}`}
                                                onClick={() => showModal(field.field_name)}
                                            />

                                            {isDeleteFieldModalVisible && (
                                                <EuiOverlayMask>
                                                    <EuiConfirmModal
                                                        title="Are you sure you want to remove this field?"
                                                        onCancel={closeModal}
                                                        onConfirm={confirmRemove}
                                                        cancelButtonText="No, don't do it"
                                                        confirmButtonText="Yes, remove it"
                                                        buttonColor="danger"
                                                        defaultFocusedButton="confirm"
                                                    >
                                                        <p>You are about to remove the field "{fieldToRemove}"</p>
                                                    </EuiConfirmModal>
                                                </EuiOverlayMask>
                                            )}
                                        </>
                                    </EuiFlexGroup>

                                    <EuiSpacer size="s" />

                                    <EuiFlexGroup direction="row" gutterSize="m" responsive={false}>
                                        <EuiFlexItem grow={1}>
                                            <EuiFormRow label="Field Type">
                                                <EuiSelect
                                                    options={fieldTypeOptions}
                                                    value={field.field_type}
                                                    onChange={(e) => {
                                                        const updatedFields = [...parsedFields];
                                                        updatedFields[index].field_type = e.target.value;
                                                        setParsedFields(updatedFields);
                                                    }}
                                                    aria-label={`Select type for ${field.field_name}`}
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>

                                        <EuiFlexItem grow={1}>
                                            <EuiFormRow label="Tokenizer" helpText="How would you like to break down the text for searching?">
                                                <EuiSelect
                                                    options={tokenizerOptions}
                                                    value={search_settings[index]?.tokenizer}
                                                    onChange={(e) => updateFieldSetting(index, 'tokenizer', e.target.value)}
                                                    aria-label={`Select tokenizer for ${field.field_name}`}
                                                />
                                            </EuiFormRow>
                                        </EuiFlexItem>

                                        <EuiFlexItem grow={1}>
                                            <EuiFormRow label="Allow Phrase Query" helpText="Enable to allow exact phrase searches on this field.">
                                                <EuiToolTip content="This option allows you to perform searches for exact phrases. Useful for fields with long text.">
                                                    <EuiSwitch
                                                        label=""
                                                        checked={search_settings[index]?.allow_phrase_queries || false}
                                                        onChange={(e) =>
                                                            updateFieldSetting(index, 'allow_phrase_queries', e.target.checked)
                                                        }
                                                        aria-label={`Toggle phrase query for ${field.field_name}`}
                                                    />
                                                </EuiToolTip>

                                            </EuiFormRow>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={1}>
                                            <EuiFormRow label="Allow Sorting" helpText="Enable to allow sorting by this field.">
                                                <EuiToolTip content="This option allows you to sort by this field, when querying your data">
                                                    <EuiSwitch
                                                        label=""
                                                        checked={search_settings[index]?.sortable || false}
                                                        onChange={(e) =>
                                                            updateFieldSetting(index, 'sortable', e.target.checked)
                                                        }
                                                        aria-label={`Toggle sortable for ${field.field_name}`}
                                                    />
                                                </EuiToolTip>

                                            </EuiFormRow>
                                        </EuiFlexItem>

                                    </EuiFlexGroup>
                                </EuiFlexGroup>
                            </EuiPanel>
                        ))}

                        <EuiSpacer size="m" />

                        {/* <EuiButtonEmpty iconType="plusInCircle" onClick={addField}>
                            Add Field
                        </EuiButtonEmpty> */}

                        <EuiSpacer size="xl" />

                        <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiButtonEmpty onClick={onBack} iconType="arrowLeft">
                                Back
                            </EuiButtonEmpty>
                            <EuiButton onClick={onNext} fill iconType="arrowRight">
                                Next
                            </EuiButton>
                        </EuiFlexGroup>
                    </EuiFlexGroup>
                )}

                {/* Step 3: Deduplication Settings */}
                {step === 3 && (
                    <EuiFlexGroup direction="column" gutterSize="l">
                        <EuiText>
                            <h2>Deduplication Settings</h2>
                        </EuiText>
                        <EuiSpacer size="l" />

                        <EuiFlexItem>
                            <EuiFormRow label="Do you want to enforce deduplication?" helpText="Enable deduplication to remove duplicate documents based on selected fields.">
                                <EuiSwitch
                                    label="Enforce Deduplication"
                                    checked={enforceDeduplication}
                                    onChange={(e) => setEnforceDeduplication(e.target.checked)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>

                        {enforceDeduplication && !useDynamicMapping && (
                            <EuiFlexItem>
                                <EuiFormRow
                                    label="Deduplication Fields"
                                    helpText="Select up to 3 fields for deduplication."
                                    isInvalid={deduplicationError !== ''}
                                    error={deduplicationError}
                                >
                                    <EuiComboBox
                                        placeholder="Select fields"
                                        options={parsedFields.map((field) => ({
                                            label: field.field_name,
                                        }))}
                                        selectedOptions={deduplicationFields}
                                        onChange={onDeduplicationFieldsChange}
                                        isClearable
                                        aria-label="Select fields for deduplication"
                                        isInvalid={deduplicationError !== ''}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        )}

                        <EuiSpacer size="l" />
                        <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiButtonEmpty onClick={onBack}>Back</EuiButtonEmpty>
                            <EuiButton onClick={onNext} fill disabled={deduplicationError !== ''} iconType="arrowRight">
                                Next
                            </EuiButton>
                        </EuiFlexGroup>
                    </EuiFlexGroup>
                )}

                {/* Step 4: Retention Policy */}
                {step === 4 && (
                    <EuiFlexGroup direction="column" gutterSize="l">
                        <EuiText>
                            <h2>Retention Policy</h2>
                        </EuiText>
                        <EuiSpacer size="l" />

                        <EuiFlexItem>
                            <EuiFormRow label="Do you want to enforce a retention policy?" helpText="Enable retention policy to automatically delete old documents.">
                                <EuiSwitch
                                    label="Enforce Retention Policy"
                                    checked={enforceRetention}
                                    onChange={(e) => setEnforceRetention(e.target.checked)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>

                        {enforceRetention && (
                            <EuiFlexItem>
                                <EuiFormRow label="Retention Days" helpText="Define how many days documents should be retained.">
                                    <EuiFieldNumber
                                        placeholder="Enter number of days"
                                        value={retentionDays}
                                        onChange={(e) => setRetentionDays(e.target.value)}
                                        min={1}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        )}
                        {enforceRetention && (
                            <EuiFlexItem>
                                <EuiFormRow label="Timestamp Field" helpText="A timestamp field is required to enforce retention policy.">
                                    <EuiSelect
                                        placeholder="Select a Timestamp field"

                                        options={parsedFields
                                            .filter((field) => field.field_type == 'timestamp')
                                            .map((field) => ({ value: field.field_name, text: field.field_name }))
                                        }
                                        value={timestampField}
                                        onChange={(e) => {
                                            setTimestampField(e.target.value);
                                        }}
                                    />

                                </EuiFormRow>
                            </EuiFlexItem>
                        )}

                        <EuiSpacer size="l" />
                        <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiButtonEmpty onClick={onBack}>Back</EuiButtonEmpty>
                            <EuiButton onClick={onNext} fill iconType="arrowRight">
                                Next
                            </EuiButton>
                        </EuiFlexGroup>
                    </EuiFlexGroup>
                )}

                {/* Step 5: Advanced Configuration */}
                {advancedConfig && step === 5 && (
                    <EuiFlexGroup direction="column" gutterSize="l">
                        <EuiText>
                            <h2>Advanced Configuration</h2>
                        </EuiText>
                        <EuiSpacer size="l" />

                        {/* Tag Fields */}
                        <EuiFlexItem>
                            <EuiFormRow label="Tag Fields" helpText="Are there any fields you want to use to label or categorize your data for easy filtering or searching?">
                                <EuiComboBox
                                    placeholder="Select tag fields"
                                    options={parsedFields
                                        .filter((field) => field.tokenizer == 'raw')
                                        .map((field) => ({ label: field.field_name }))
                                    }
                                    selectedOptions={tagFields}
                                    onChange={(selected) => setTagFields(selected)}
                                    isClearable
                                />
                            </EuiFormRow>
                        </EuiFlexItem>

                        {/* Partition Key Fields */}
                        <EuiFlexItem>
                            <EuiFormRow label="Partition Key" helpText="Helps you distribute your data into groups for faster access">
                                <EuiSelect
                                    placeholder="Select a partition key"
                                    options={parsedFields.map((field) => ({ value: field.field_name, text: field.field_name }))}
                                    value={partitionKey}
                                    onChange={(e) => setPartitionKey(e.target.value)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>

                        <EuiSpacer size="l" />
                        <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiButtonEmpty onClick={onBack}>Back</EuiButtonEmpty>
                            <EuiButton onClick={onNext} fill iconType="arrowRight">
                                Next
                            </EuiButton>
                        </EuiFlexGroup>
                    </EuiFlexGroup>
                )}



                {step === 6 && (
                    <EuiFlexGroup direction="column" gutterSize="l">
                        <EuiText>
                            <h2>Review and Edit Configuration</h2>
                        </EuiText>
                        <EuiSpacer size="l" />
                        <EuiFlexItem>
                            <ReactJson
                                src={collectedData}
                                // onEdit={handleJsonEdit}
                                // onAdd={handleJsonEdit}
                                onDelete={handleJsonEdit}
                                theme="twilight"
                                displayDataTypes={false}
                                displayObjectSize={false}
                                enableClipboard={false}
                                style={{ padding: '10px' }}
                                name={null}

                            />
                        </EuiFlexItem>
                        <EuiSpacer size="l" />
                        <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiButtonEmpty onClick={onBack}>Back</EuiButtonEmpty>
                            <EuiButton type="submit" onClick={onSubmit} fill iconType="arrowRight">
                                Submit
                            </EuiButton>
                        </EuiFlexGroup>
                    </EuiFlexGroup>
                )}
            </EuiForm>
        </EuiPanel>
    );
};

export default CreateIndexWorkflow;
