import React, { useEffect, useState } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiBreadcrumbs,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiButton,
    EuiLink,
    EuiBadge,
    EuiSpacer,
    EuiHorizontalRule,
    EuiTable,
    EuiTableHeader,
    EuiTableHeaderCell,
    EuiTableBody,
    EuiTableRow,
    EuiTableRowCell,
    EuiText,
    EuiAvatar,
    EuiHeader
} from '@elastic/eui';
import HeaderWithSearch from '../../components/HeaderWithSearch';
import { listDeployments } from '../../utils/api';
import { useNavigate } from 'react-router-dom';

const DeploymentListPage = () => {
    const [deployments, setDeployments] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const breadcrumbs = [
        {
            text: 'Cloud',
        },
        {
            text: 'Deployments',
            href: '/deployments',
        },
    ];

    useEffect(() => {
        const fetchDeployments = async () => {
            try {
                const response = await listDeployments();
                setDeployments(response.data);
            } catch (error) {
                console.error('Error fetching deployments:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDeployments();
    }, []);

    return (
        <EuiPage paddingSize="l">
            <HeaderWithSearch showSearch={false} />
            <EuiHeader
                position="fixed"
                sections={[
                    {
                        items: [
                            <EuiAvatar type="space" name="Default Space" size="s" />,
                        ],
                        breadcrumbs: breadcrumbs,
                    },
                ]}
            />

            <EuiPageBody>


                <EuiSpacer size="xxl" />

                <EuiPageHeader style={{ marginTop: "5rem" }}>
                    <EuiPageHeaderSection>
                        <EuiTitle size="l">
                            <h1>Welcome to SearchSphere</h1>
                        </EuiTitle>
                    </EuiPageHeaderSection>
                </EuiPageHeader>

                <EuiSpacer size="l" />

                {/* Hosted Deployments Section */}
                <EuiPanel paddingSize="l">
                    <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
                        <EuiFlexItem>
                            <EuiTitle size="m">
                                <h2>Hosted deployments</h2>
                            </EuiTitle>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton onClick={() => { navigate("/deployments/create") }} fill>Create deployment</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="m" />
                    {loading ? (
                        <EuiText>Loading...</EuiText>
                    ) : deployments.length === 0 ? (
                        <EuiFlexGroup direction="column" alignItems="center" justifyContent="center" style={{ textAlign: 'center', width: "50%", marginLeft: "auto", marginRight: "auto", padding: "2rem" }}>
                            <EuiFlexItem grow={false}>
                                <EuiText>
                                    <h3>You have no hosted deployments yet</h3>
                                    <p>
                                        Deploy an SearchSphere Stack with all Search, Observabilityincluded. Customize its size and performance to your exact needs. <EuiLink href="#">Learn more</EuiLink>
                                    </p>
                                </EuiText>
                            </EuiFlexItem>

                        </EuiFlexGroup>
                    ) : (
                        <EuiTable>
                            <EuiTableHeader>
                                <EuiTableHeaderCell>Deployment</EuiTableHeaderCell>
                                <EuiTableHeaderCell>Status</EuiTableHeaderCell>
                                <EuiTableHeaderCell>Version</EuiTableHeaderCell>
                                <EuiTableHeaderCell>Cloud provider & region</EuiTableHeaderCell>
                                <EuiTableHeaderCell>Actions</EuiTableHeaderCell>
                            </EuiTableHeader>
                            <EuiTableBody>
                                {deployments.map((deployment) => (
                                    <EuiTableRow key={deployment.id}>
                                        <EuiTableRowCell>{deployment.name}</EuiTableRowCell>
                                        <EuiTableRowCell>
                                            <EuiBadge color={deployment.status === 'HEALTHY' ? 'success' : 'danger'}>
                                                {deployment.status}
                                            </EuiBadge>
                                        </EuiTableRowCell>
                                        <EuiTableRowCell>{deployment?.version?.version_number}</EuiTableRowCell>
                                        <EuiTableRowCell>{deployment?.cloud_provider?.name} | {deployment?.cloud_provider?.region}</EuiTableRowCell>
                                        <EuiTableRowCell>
                                            <EuiFlexGroup gutterSize="s" alignItems="center">
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton size="s" disabled={deployment.status != 'HEALTHY'} onClick={() => navigate(`/deployments/view/${deployment.uuid}/app`)}>Open</EuiButton>
                                                </EuiFlexItem>
                                                {/* <EuiFlexItem grow={false}>
                                                    <EuiButton size="s" onClick={() => navigate(`/deployments/${deployment.uuid}/manage`)}>Manage</EuiButton>
                                                </EuiFlexItem> */}
                                                <EuiFlexItem grow={false}>
                                                    <EuiButton color='danger' size="s" onClick={() => navigate(`/deployments/view/${deployment.uuid}/demo`)}>Try Demo</EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiTableRowCell>
                                    </EuiTableRow>
                                ))}
                            </EuiTableBody>
                        </EuiTable>
                    )}
                </EuiPanel>

                <EuiSpacer size="l" />
            </EuiPageBody>
        </EuiPage>
    );
};

export default DeploymentListPage;