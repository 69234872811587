import React, { useState, useEffect } from 'react';
import { EuiBasicTable, EuiPanel, EuiSpacer, EuiText, EuiBadge, EuiFlexGroup, EuiFlexItem, EuiButton } from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import { fetchDeleteRequests } from '../../utils/api';

const DeleteRequests = ({ deploymentId, indexId }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchDeleteRequests(indexId, {
                    status: '',
                    startTimestamp: '',
                    endTimestamp: '',
                });
                setData(response?.data?.delete_requests || []);
                setTotalItemCount(response?.data?.pagination?.total || 0);
            } catch (error) {
                console.error('Failed to fetch delete requests:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [indexId, pageIndex, pageSize]);

    const onTableChange = ({ page = {} }) => {
        const { index: newPageIndex, size: newPageSize } = page;

        setPageIndex(newPageIndex);
        setPageSize(newPageSize);
    };

    // Define the columns for the table
    const columns = [
        {
            field: 'query',
            name: 'Query',
            sortable: true,
            dataType: 'string',
        },
        {
            field: 'search_fields',
            name: 'Search Fields',
            render: (searchFields) => searchFields?.join(', '),
        },
        {
            field: 'start_timestamp',
            name: 'Start Timestamp',
            sortable: true,
            dataType: 'number',
        },
        {
            field: 'end_timestamp',
            name: 'End Timestamp',
            sortable: true,
            dataType: 'number',
        },
        {
            field: 'status',
            name: 'Status',
            render: (status) => {
                let color = 'default';
                if (status === 'COMPLETED') color = 'success';
                else if (status === 'IN PROGRESS') color = 'primary';
                else if (status === 'PENDING') color = 'warning';
                else if (status === 'FAILED') color = 'danger';
                return <EuiBadge color={color}>{status}</EuiBadge>;
            },
        },
    ];

    const pagination = {
        pageIndex,
        pageSize,
        totalItemCount,
        pageSizeOptions: [5, 10, 20],
    };

    // Render the table
    return (
        <EuiPanel paddingSize="l">
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiText>
                        <h2>Delete Requests</h2>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiText size="s" color="subdued">
                        <p>Delete documents are executed asynchronously. The status will be marked as completed once the data matching your query is deleted.</p>
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton color='success' onClick={() => {
                        navigate(
                            `/deployments/view/${deploymentId}/app/indices/details/${indexId}/create-delete-requests`
                        )
                    }} fill>Create Delete Request</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="l" />
            <EuiBasicTable
                items={data}
                columns={columns}
                rowHeader="query"
                pagination={pagination}
                onChange={onTableChange}
                loading={loading}
            />
        </EuiPanel>
    );
};

export default DeleteRequests;