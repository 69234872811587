import React, { createContext, useContext, useReducer } from 'react';
import { setUserDispatch } from './api';

// Initial state for the user context
const initialState = {
    user: null,
};

// User reducer to manage user-related state changes
const userReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, user: action.payload };
        case 'LOGOUT_USER':
            return { ...state, user: null };
        default:
            return state;
    }
};

// Create User Context
const UserContext = createContext();

// UserProvider component to wrap the application and provide user context
export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);

    // Set the userDispatch in api.js
    setUserDispatch(dispatch);

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};
