import React, { useState, useEffect } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiLink,
    EuiBadge,
    EuiButton,
    EuiSelectableTemplateSitewide,
    EuiHeader,
    EuiHeaderSectionItem,
    EuiHeaderLogo,
    EuiHeaderSectionItemButton,
    EuiSpacer,
    useEuiTheme,
} from '@elastic/eui';
import { css } from '@emotion/react';
import UserPopover from '../components/UserPopover';
import { useNavigate } from 'react-router-dom';

const SitewideSearch = () => {
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [searchRef, setSearchRef] = useState(null);
    const searchValueExists = searchValue && searchValue.length;

    let searchTimeout;

    const startSearchTimeout = () => {
        searchTimeout = setTimeout(() => {
            setLoading(false);
        }, 400);
    };

    clearTimeout(searchTimeout);
    startSearchTimeout();

    const recents = searchData.slice(0, 5);
    const recentsWithIcon = recents.map((recent) => ({
        ...recent,
        icon: {
            type: 'clock',
            color: 'subdued',
        },
    }));

    useEffect(() => {
        const onWindowKeyUp = () => {
            searchRef?.focus();
            setLoading(true);
            window.removeEventListener('keyup', onWindowKeyUp);
        };

        const onWindowKeyDown = (e) => {
            if (e.metaKey && e.key.toLowerCase() === 'k') {
                e.preventDefault();
                window.addEventListener('keyup', onWindowKeyUp);
            }
        };

        window.addEventListener('keydown', onWindowKeyDown);
        return () => {
            window.removeEventListener('keydown', onWindowKeyDown);
            window.removeEventListener('keyup', onWindowKeyUp);
        };
    }, [searchRef]);

    const onKeyUpCapture = (e) => {
        setSearchValue(e.currentTarget.value);
    };

    const onChange = (updatedOptions) => {
        const clickedItem = updatedOptions.find((option) => option.checked === 'on');
        if (!clickedItem) return;
        // Handle the selection of an item
    };

    const { euiTheme } = useEuiTheme();

    return (
        <EuiSelectableTemplateSitewide
            isLoading={isLoading}
            isPreFiltered={false}

            onChange={onChange}
            options={searchValueExists ? searchData : recentsWithIcon}
            searchProps={{
                append: '⌘K',
                onKeyUpCapture: onKeyUpCapture,
                className: 'search-bar',
                inputRef: setSearchRef,
            }}
            listProps={{
                className: 'search-list-items',
                css: css`
      .euiSelectableTemplateSitewide__optionMeta--PINK {
        font-weight: ${euiTheme.font.weight.medium};
        color: ${euiTheme.colors.accentText};
      }
    `,
            }}
            popoverProps={{
                className: 'search-bar-popover',
            }}
            popoverButton={<EuiButton>Mobile toggle</EuiButton>}
            popoverButtonBreakpoints={['xs', 's']}
            popoverFooter={
                <EuiText color="subdued" size="xs">
                    <EuiFlexGroup
                        alignItems="center"
                        gutterSize="s"
                        responsive={false}
                        justifyContent="center"
                        wrap
                    >
                        <EuiFlexItem grow={false}>
                            {searchValueExists && <EuiLink>View more results</EuiLink>}
                        </EuiFlexItem>
                        <EuiFlexItem />
                        <EuiFlexItem grow={false}>Quickly search using</EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiBadge>Command + K</EuiBadge>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiText>
            }
        />
    );
};

/**
 * The options object
 */
const searchData = [
    {
        label: 'Welcome dashboards',
        avatar: {
            name: 'Default Space',
        },
        meta: [
            {
                text: 'Dashboard',
                type: 'application',
                highlightSearchString: true,
            },
        ],
        url: 'welcome-dashboards',
    },
    {
        label: '[Flights] Flight Count and Average Ticket Price',
        avatar: {
            name: 'Default Space',
        },
        meta: [
            {
                text: 'Visualization',
                type: 'application',
            },
        ],
    },
    // More data...
];

const HeaderWithSearch = (props) => {
    const [showSearch, setShowSearch] = useState(props.showSearch);
    const navigate = useNavigate();

    // Add theme detection hook
    const [isDark, setIsDark] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    // Listen for theme changes
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handler = (e) => setIsDark(e.matches);

        mediaQuery.addListener(handler);
        return () => mediaQuery.removeListener(handler);
    }, []);

    return (
        <>
            <EuiHeader
                position="fixed"
                className='hs-header'
                sections={[
                    {
                        items: [
                            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' className='' style={{ padding: '0.5rem 0' }}>
                                <EuiFlexItem
                                    style={{
                                        padding: "1rem",

                                    }}
                                >
                                    <img
                                        src={isDark ? "/ssphere_white.png" : "/ssphere_black.png"}
                                        alt="SearchSphere Logo"
                                        style={{
                                            height: '35px',
                                            width: '160px',
                                            marginRight: '8px'
                                        }}
                                        onClick={() => navigate('/')}
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>,
                            <EuiFlexGroup justifyContent="center" alignItems='center' style={{ width: "100%" }}>
                                {
                                    showSearch && <EuiFlexItem className='hs-search-bar' grow={false} style={{ flexBasis: '40%', marginLeft: "auto" }}>
                                        <SitewideSearch isModalOpen={props.isModalOpen} />
                                    </EuiFlexItem>
                                }
                                <EuiFlexItem grow={true} >
                                    <UserPopover />
                                </EuiFlexItem>
                            </EuiFlexGroup>,

                        ],
                    },
                ]}
            />
            <EuiSpacer size="l" />
        </>
    );
};


export default HeaderWithSearch;
