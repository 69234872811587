import React, { useEffect, useState } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiText,
    EuiSpacer,
    EuiBadge,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiButton,
    EuiSelect,
    EuiLink,
    EuiIcon,
    EuiCallOut,
    EuiButtonIcon,
} from '@elastic/eui';
import { useParams } from 'react-router-dom';
import { getConnectorById } from '../../../utils/api';

const CustomIconUrl = {
    'Azure Blob Storage': 'https://img.icons8.com/color/48/azure.png',
    Kafka: 'https://img.icons8.com/?size=100&id=fOhLNqGJsUbJ&format=png&color=000000',
    'Google Cloud Storage': 'https://img.icons8.com/?size=100&id=gcp_icon&format=png',
    Outlook: 'https://img.icons8.com/?size=100&id=outlook_icon&format=png',
};


const ConfigurationTab = () => {
    const { connectorId } = useParams();
    const [connector, setConnector] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchConnectorDetails = async () => {
            setLoading(true);
            try {
                const response = await getConnectorById(connectorId);
                setConnector(response.data);
            } catch (error) {
                console.error('Error fetching connector details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchConnectorDetails();
    }, [connectorId]);

    if (loading) {
        return <EuiText>Loading...</EuiText>;
    }

    if (!connector) {
        return <EuiText>Connector details not found.</EuiText>;
    }

    return (
        <EuiPage paddingSize="l" style={{ minHeight: '100vh' }}>
            <EuiPageBody>
                <EuiPageHeader>
                    <EuiPageHeaderSection>
                        <EuiFlexGroup direction='row'>
                            <EuiFlexItem grow={false}>
                                <img
                                    src={CustomIconUrl[connector.type]}
                                    alt={`${connector.type} icon`}
                                    style={{ width: '32px', height: '32px', }}
                                />
                            </EuiFlexItem >
                            <EuiFlexItem grow={false}>
                                <EuiTitle size="l">
                                    <h1>{connector.type} <EuiBadge color="default">Native connector</EuiBadge></h1>
                                </EuiTitle>

                            </EuiFlexItem>

                        </EuiFlexGroup>

                    </EuiPageHeaderSection>
                </EuiPageHeader>

                <EuiSpacer size="l" />

                {/* Attach an Index Section */}
                <EuiPanel paddingSize="l" style={{ borderRadius: '8px' }}>
                    <EuiText>
                        <h2>Attached index</h2>
                        <p>
                            This index will hold your data source content, and is optimized with default field mappings for relevant search experiences.
                            Give your index a unique name and optionally set a default language analyzer for the index.
                        </p>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiSelect
                        disabled={true}
                        options={[
                            { value: 'test1', text: 'test1' },
                            // Add other index options if needed
                        ]}
                        value="test1"
                        aria-label="Associated index"
                        prepend="Associated index"
                    />
                    <EuiSpacer size="s" />

                </EuiPanel>

                <EuiSpacer size="l" />

                {/* Configuration Section */}
                <EuiPanel paddingSize="l" style={{ borderRadius: '8px' }}>
                    <EuiText>
                        <h2>Configuration</h2>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiCallOut color="primary" title="Check Requirements" iconType="iInCircle">
                        Refer to the documentation for this connector to learn about prerequisites for connecting to Azure Blob Storage and configuration requirements.
                        <EuiSpacer size="s" />
                        <EuiLink href="https://docs.example.com" target="_blank">Documentation</EuiLink>
                        <EuiSpacer size="xs" />
                        <EuiLink href="https://azure.microsoft.com/en-us/services/storage/" target="_blank">Azure Blob Storage documentation</EuiLink>
                    </EuiCallOut>

                    <EuiSpacer size="m" />

                    <EuiText>
                        <EuiLink href="https://azure.microsoft.com/en-us/services/storage/" target="_blank">
                            Azure Blob Storage authentication
                        </EuiLink>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiText>
                        <strong>Account name</strong><br /> {connector.config.account_name || '--'}
                    </EuiText>
                    <EuiSpacer size="s" />
                    <EuiText>
                        <strong>Account key</strong><br /> {connector.config.account_key || '--'}
                    </EuiText>
                    <EuiSpacer size="s" />
                    <EuiText>
                        <strong>Blob endpoint</strong><br /> {connector.config.blob_endpoint || '--'}
                    </EuiText>
                    <EuiSpacer size="s" />
                    <EuiText>
                        <strong>List of containers</strong><br /> {connector.config.list_of_containers || '--'}
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiButton color="primary" fill>
                        Edit configuration
                    </EuiButton>
                </EuiPanel>

                <EuiSpacer size="l" />

                {/* Error Message Section */}
                <EuiCallOut title="Connector error" color="danger" iconType="alert" style={{ backgroundColor: '#5c2b29', borderRadius: '8px' }}>
                    Field validation errors: 'Account name' cannot be empty.; 'Account key' cannot be empty.; 'Blob endpoint' cannot be empty.; 'List of containers' cannot be empty.
                </EuiCallOut>

                <EuiSpacer size="l" />

                {/* API Key Section */}
                <EuiPanel paddingSize="l" style={{ borderRadius: '8px' }}>
                    <EuiText>
                        <h2>API Key</h2>
                        <p>
                            This native connector's API key <strong>{connector.connector_id}-connector</strong> is managed internally by Elasticsearch. The connector uses this API key to index documents into the <strong>{connector.index_name}</strong> index.
                        </p>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiButton color="primary" fill>Generate API key</EuiButton>
                </EuiPanel>

                <EuiSpacer size="l" />

                {/* Self-manage this connector Section */}
                <EuiPanel paddingSize="l" style={{ borderRadius: '8px' }}>
                    <EuiText>
                        <h2>Self-manage this connector</h2>
                        <p>
                            Want to self-host this native connector? Convert it to a <EuiLink href="https://connector-client-link.com" target="_blank">connector client</EuiLink>, to be self-managed on your own infrastructure. You'll need to convert this connector if you want to customize the code using our Python framework.
                        </p>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiButton color="primary" fill>Convert connector</EuiButton>
                </EuiPanel>

                <EuiSpacer size="l" />

                {/* What's Next Section */}
                <EuiPanel paddingSize="l" style={{ borderRadius: '8px' }}>
                    <EuiText>
                        <h2>What's next?</h2>
                        <p>
                            You can manually sync your data, schedule a recurring sync or see your documents.
                        </p>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiFlexGroup gutterSize="s">
                        <EuiFlexItem grow={false}>
                            <EuiButton color="primary" iconType="play">
                                Search Playground
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton color="primary" iconType="calendar">
                                Set schedule and sync
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton color="primary" iconType="arrowRight">
                                Sync
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </EuiPageBody>
        </EuiPage>
    );
};

export default ConfigurationTab;