import React, { useEffect, useState } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiCard,
    EuiIcon,
    EuiText,
    EuiTitle,
    EuiFieldSearch,
    EuiSwitch,
    EuiBadge,
    EuiSpacer,
    EuiPanel,
    EuiNotificationBadge,
    EuiHorizontalRule,
} from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import { useNavigate, useParams } from 'react-router-dom';
import { listDeploymentIndexes } from '../../utils/api';

const connectors = [
    // {
    //     title: 'Azure Blob Storage',
    //     type: 'Native',
    //     techPreview: false,
    //     documentationUrl: '#',
    //     iconType: 'logoAzure',
    // },
    // {
    //     title: 'Google Cloud Storage',
    //     type: 'Native',
    //     techPreview: false,
    //     documentationUrl: '#',
    //     iconType: 'logoGoogleG',
    // },
    // {
    //     title: 'Microsoft SQL',
    //     type: 'Native',
    //     techPreview: false,
    //     documentationUrl: '#',
    //     iconType: 'custom', // Custom for Microsoft SQL
    //     customIconUrl: 'https://img.icons8.com/?size=100&id=laYYF3dV0Iew&format=png&color=000000', // Microsoft SQL icon link
    // },
    {
        title: 'Apache Kafka',
        type: 'Native',
        techPreview: false,
        documentationUrl: '#',
        iconType: 'custom', // Custom for Apache Kafka
        customIconUrl: 'https://img.icons8.com/?size=100&id=fOhLNqGJsUbJ&format=png&color=000000', // Apache Kafka icon link
    },
    // {
    //     title: 'Apache Pulsar',
    //     type: 'Native',
    //     techPreview: true,
    //     documentationUrl: '#',
    //     iconType: 'logoApache',
    // },
    // {
    //     title: 'Amazon Kinesis',
    //     type: 'Native',
    //     techPreview: false,
    //     documentationUrl: '#',
    //     iconType: 'logoCloud', // Placeholder for Amazon Kinesis
    // },
    // {
    //     title: 'CSV File',
    //     type: 'File',
    //     techPreview: false,
    //     documentationUrl: '#',
    //     iconType: 'document', // CSV icon
    // },
    // {
    //     title: 'JSON File',
    //     type: 'File',
    //     techPreview: false,
    //     documentationUrl: '#',
    //     iconType: 'document', // JSON icon
    // },
    {
        title: 'API Connector',
        type: 'API',
        techPreview: true,
        documentationUrl: '#',
        iconType: 'apmTrace', // API icon from EUI
    },
];

const CreateConnectorPage = () => {
    const [searchValue, setSearchValue] = useState('');
    const [betaFilter, setBetaFilter] = useState(false);
    const [techPreviewFilter, setTechPreviewFilter] = useState(false);

    const navigate = useNavigate();
    const { deploymentId } = useParams();

    const filteredConnectors = connectors.filter(
        (connector) =>
            connector.title.toLowerCase().includes(searchValue.toLowerCase()) &&
            (!techPreviewFilter || connector.techPreview) // filter for tech preview
    );



    return (
        <div>
            {/* Header */}
            <EuiTitle size="l">
                <h1>Select a connector</h1>
            </EuiTitle>
            <EuiText>
                <p>Choose which third-party data source you'd like to sync to SearchSphere deployment</p>
            </EuiText>

            <EuiSpacer size="l" />

            <EuiFlexGroup>
                {/* Sidebar */}
                <EuiFlexItem grow={false}>
                    <EuiPanel paddingSize="m" style={{ width: 250 }}>
                        {/* Navigation with badges */}
                        <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={3}>
                                <EuiText>
                                    <strong>All connectors</strong>
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={1}>
                                <EuiNotificationBadge color="subdued" style={{ marginLeft: 8 }}>
                                    {connectors.length}
                                </EuiNotificationBadge>
                            </EuiFlexItem>
                        </EuiFlexGroup>

                        <EuiSpacer size="s" />
                        <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={3}>
                                <EuiText>
                                    Native connectors
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={1}>
                                <EuiNotificationBadge color="subdued" style={{ marginLeft: 8 }}>
                                    {connectors.filter((c) => c.type === 'Native').length}
                                </EuiNotificationBadge>
                            </EuiFlexItem>
                        </EuiFlexGroup>

                        <EuiHorizontalRule></EuiHorizontalRule>

                        <EuiSpacer size="s" />
                        <EuiSwitch
                            label="Display Beta connectors"
                            checked={betaFilter}
                            onChange={(e) => setBetaFilter(e.target.checked)}
                        />
                        <EuiSpacer size="s" />
                        <EuiSwitch
                            label="Display Tech Preview connectors"
                            checked={techPreviewFilter}
                            onChange={(e) => setTechPreviewFilter(e.target.checked)}
                        />
                    </EuiPanel>
                </EuiFlexItem>

                {/* Main content area */}
                <EuiFlexItem>
                    {/* Search bar */}
                    <EuiFieldSearch
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        isClearable
                        fullWidth
                    />
                    <EuiSpacer size="m" />

                    {/* Connector cards with 3 per row */}
                    <EuiFlexGroup wrap gutterSize="l">
                        {filteredConnectors.map((connector) => (
                            <EuiFlexItem key={connector.title} grow={false} style={{ width: '30%' }}>
                                <EuiCard
                                    onClick={() => navigate(`/deployments/view/${deploymentId}/app/connectors/create/kafka`)}
                                    icon={
                                        connector.iconType === 'custom' ? (
                                            <img src={connector.customIconUrl} alt={connector.title} style={{ width: '40px' }} />
                                        ) : (
                                            <EuiIcon size="xl" type={connector.iconType} />
                                        )
                                    }
                                    title={connector.title}
                                    description={
                                        <>
                                            <EuiBadge color="hollow">{connector.type}</EuiBadge>
                                            {connector.techPreview && (
                                                <>
                                                    <EuiBadge iconType="beaker" color="warning">
                                                        Tech Preview
                                                    </EuiBadge>
                                                </>
                                            )}
                                        </>
                                    }
                                    footer={
                                        <a href={connector.documentationUrl} target="_blank" rel="noopener noreferrer">
                                            Documentation
                                        </a>
                                    }
                                />
                            </EuiFlexItem>
                        ))}
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </div>
    );
};

export default CreateConnectorPage;
