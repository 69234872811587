import axios from 'axios';
import * as yup from 'yup';
import { createContext, useContext, useReducer } from 'react';
import { getGlobalErrorHandler } from './global-toast';

export const cleanCollectedData = (collected_data) => {
  for (const key in collected_data) {
    if (collected_data[key] === null || collected_data[key] === undefined || collected_data[key] === '' || collected_data[key]?.length === 0) {
      delete collected_data[key];
    }
  }

  return collected_data;
};


// Base URL (this could be an environment variable or set based on the environment)
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Default headers
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

// Function to get authorization token (replace logic based on how you manage auth tokens)
const getAuthToken = () => {
  return localStorage.getItem('access_token');
};


const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const addGlobalError = getGlobalErrorHandler();
    if (addGlobalError) {
      addGlobalError(error.response?.data?.message || error.response?.data?.error?.message || 'Something went wrong, please try again.');
    }
    return Promise.reject(error);
  }
);

// General API request function
const apiRequest = async (endpoint, method, data = {}, headers = {}) => {
  try {
    const token = getAuthToken();
    const authHeaders = (endpoint !== '/api/v1/auth/login' && endpoint !== '/api/v1/auth/register') && token ? { Authorization: `Bearer ${token}` } : {};
    const combinedHeaders = { ...DEFAULT_HEADERS, ...authHeaders, ...headers };

    const response = await axiosInstance({
      method,
      url: `${API_BASE_URL}${endpoint}`,
      headers: combinedHeaders,
      data,
    });

    return response.data;
  } catch (error) {
    console.error('API Request Failed:', error);
    throw error.response ? error.response.data : error;
  }
};

// Request validation schemas using Yup

// Indexes
export const createIndexSchema = yup.object().shape({
  index_name: yup.string().required(),
  search_settings: yup.array(),

  enforce_deduplication: yup.boolean().required(),
  deduplication_fields: yup.array().of(yup.string()),

  enforce_retention: yup.boolean().required(),
  retention_days: yup.number().integer().positive(),
  timestamp_field: yup.string(),

  advanced_config: yup.boolean().required(),
  tag_fields: yup.array().of(yup.string()).required(),
  partition_key: yup.string().required(),
  default_search_fields: yup.array().of(yup.string()).required(),

  deployment_id: yup.string().required(),

  use_dynamic_mapping: yup.boolean().required(),
});

const bulkDeleteIndexesSchema = yup.object().shape({
  indexes: yup.array().of(yup.string().required()).required(),
});

// Deployments
const createDeploymentSchema = yup.object().shape({
  name: yup.string().required(),
  version_id: yup.string().required(),
  cloud_provider_id: yup.string().required(),
});

// Connectors
export const createKafkaConnectorSchema = yup.object().shape({
  connector_id: yup.string().required('Connector name is required.'),
  kafka_bootstrap_servers: yup.string().required('Bootstrap servers are required.'),
  topic_name: yup.string().required('Topic name is required.'),
  num_pipelines: yup.number().required('Number of pipelines is required.').min(1, 'Must be at least 1'),
  kafka_username: yup.string().required('Kafka username is required.'),
  kafka_password: yup.string().required('Kafka password is required.'),
  consumer_group_id: yup.string().notRequired(),
  offset_reset: yup.string().oneOf(['earliest', 'latest'], 'Invalid offset reset option'),
});

// Authentication
const loginUserSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

const registerUserSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

// API Keys
const generateApiKeySchema = yup.object().shape({
  name: yup.string().required(),
  permissions: yup.array().of(yup.string().required()).required(),
});

// Ingest
const ingestDataSchema = yup.object().shape({
  data: yup.array().of(yup.object().required()).required(),
});

// Welcome Questions
const submitWelcomeQuestionsSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().required(),
      answer: yup.string().required(),
    })
  ).required(),
});

// API functions with validation

// Indexes
export const createIndex = async (indexData) => {
  // await createIndexSchema.validate(indexData);
  return apiRequest('/api/v1/indexes', 'POST', indexData);
};

export const listDeploymentIndexes = async ({ deploymentId }) => {
  return apiRequest(`/api/v1/deployments/${deploymentId}/indexes`, 'GET');
};

export const getIndexById = async (indexId) => {
  return apiRequest(`/api/v1/indexes/${indexId}`, 'GET');
};

export const searchInAnIndex = async (indexId, searchBody) => {
  const cleanData = cleanCollectedData(searchBody);
  return apiRequest(`/api/v1/indexes/${indexId}/search`, 'POST', cleanData);
};

export const deleteDocsFromIndex = async (indexId, deleteDocsRequest) => {
  return apiRequest(`/api/v1/indexes/${indexId}/delete-requests`, 'POST', deleteDocsRequest);
};

export const fetchDeleteRequests = async (indexId, { status, startTimestamp, endTimestamp }) => {
  const params = new URLSearchParams();

  if (status) {
    params.append('status', status);
  }
  if (startTimestamp) {
    params.append('startTimestamp', startTimestamp);
  }
  if (endTimestamp) {
    params.append('endTimestamp', endTimestamp);
  }

  const queryString = params.toString();
  const url = `/api/v1/indexes/${indexId}/delete-requests${queryString ? `?${queryString}` : ''}`;

  return apiRequest(url, 'GET');
};

export const getIndexMappingByIndexId = async (indexId) => {
  return apiRequest(`/api/v1/indexes/${indexId}/mapping`, 'GET');
};

export const deleteIndex = async (indexId) => {
  return apiRequest(`/api/v1/indexes/${indexId}`, 'DELETE');
};

export const bulkDeleteIndexes = async (indexes) => {
  await bulkDeleteIndexesSchema.validate({ indexes });
  return apiRequest('/api/v1/indexes/bulk_delete', 'DELETE', { indexes });
};

// Deployments
export const createDeployment = async (deploymentData) => {
  await createDeploymentSchema.validate(deploymentData);
  return apiRequest('/api/v1/deployments', 'POST', deploymentData);
};

export const listDeployments = async () => {
  return apiRequest('/api/v1/deployments', 'GET');
};

export const getDeploymentById = async (deploymentId) => {
  return apiRequest(`/api/v1/deployments/${deploymentId}`, 'GET');
};

export const deleteDeployment = async (deploymentId) => {
  return apiRequest(`/api/v1/deployments/${deploymentId}`, 'DELETE');
};

// Connectors
export const createKafkaConnector = async (indexId, connectorData) => {
  console.log({ connectorData })
  await createKafkaConnectorSchema.validate(connectorData, { abortEarly: false });
  return apiRequest(`/api/v1/indexes/${indexId}/connectors/kafka`, 'POST', connectorData);
};

export const listDeploymentConnectors = async ({ deploymentId, page, limit }) => {
  return apiRequest(`/api/v1/deployments/${deploymentId}/connectors?page=${page}&limit=${limit}`, 'GET');
};

export const getConnectorById = async (connectorId) => {
  return apiRequest(`/api/v1/connectors/${connectorId}`, 'GET');
};

export const deleteConnector = async (connectorId) => {
  return apiRequest(`/api/v1/connectors/${connectorId}`, 'DELETE');
};

export const toggleConnector = async (connectorId, enabled) => {
  return apiRequest(`/api/v1/connectors/${connectorId}/toggle?enabled=${enabled}`, 'PUT');
};

// Authentication
export const loginUser = async (credentials) => {
  await loginUserSchema.validate(credentials);
  const response = await apiRequest('/api/v1/auth/login', 'POST', credentials);
  if (response.data.access_token) {
    localStorage.setItem('access_token', response.data.access_token);
    userDispatch({ type: 'SET_USER', payload: response.data.user });
  }
  return response;
};

export const logoutUser = () => {
  localStorage.removeItem('access_token');
  userDispatch({ type: 'LOGOUT_USER' });
};

export const registerUser = async (userData) => {
  await registerUserSchema.validate(userData);
  return apiRequest('/api/v1/auth/register', 'POST', userData);
};

export const getLoggedInUserAndNewToken = async () => {
  const response = await apiRequest('/api/v1/me', 'GET');

  if (response.data.access_token) {
    localStorage.setItem('access_token', response.data.access_token);
    userDispatch({ type: 'SET_USER', payload: response.data.user });
  }

  return response;
};

export const confirmEmail = async (token) => {
  return apiRequest(`/api/v1/auth/confirm/${token}`, 'GET');
};

// API Keys
export const generateApiKey = async (apiKeyData) => {
  await generateApiKeySchema.validate(apiKeyData);
  return apiRequest('/api/v1/api-keys', 'POST', apiKeyData);
};

// Ingest
export const ingestData = async (indexId, ingestData) => {
  await ingestDataSchema.validate(ingestData);
  return apiRequest(`/api/d/ingest/indexes/${indexId}`, 'POST', ingestData);
};

// Welcome Questions
export const submitWelcomeQuestions = async (welcomeData) => {
  await submitWelcomeQuestionsSchema.validate(welcomeData);
  return apiRequest('/api/v1/welcome', 'POST', welcomeData);
};


// Fetch Versions
export const fetchVersions = async () => {
  return apiRequest('/api/v1/versions', 'GET');
};

// Fetch Cloud Providers
export const fetchCloudProvidersWithRegion = async () => {
  return apiRequest('/api/v1/cloud-providers', 'GET');
};


// Usage of userDispatch
let userDispatch;
export const setUserDispatch = (dispatch) => {
  userDispatch = dispatch;
};