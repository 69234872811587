import React, { useState, useRef } from 'react';
import {
    EuiPageTemplate,
    EuiText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiTabs,
    EuiTab,
    EuiSpacer,
    EuiTitle,
    EuiFieldSearch,
    EuiPagination,
    EuiPanel,
    EuiIcon,
    EuiButtonIcon,
    EuiComboBox,
    EuiFormRow,
    EuiFieldText
} from '@elastic/eui';

const documentsData = [
    {
        id: 'U48yJZlBrA4eoczziTTI',
        fields: [
            { name: 'category', value: "['Men's Clothing']" },
            { name: 'currency', value: '"EUR"' },
            { name: 'customer_first_name', value: '"Eddie"' },
        ],
    },
    {
        id: 'VI8yJZlBrA4eoczziTTI',
        fields: [
            { name: 'category', value: "['Women's Clothing']" },
            { name: 'currency', value: '"EUR"' },
            { name: 'customer_first_name', value: '"Mary"' },
        ],
    },
    {
        id: 'VY8yJZlBrA4eoczziTTI',
        fields: [
            { name: 'category', value: "['Women's Shoes', 'Women's Clothing']" },
            { name: 'currency', value: '"EUR"' },
            { name: 'customer_first_name', value: '"Laura"' },
        ],
    },
];

const IndexDocumentsView = ({ indexFields }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = 187; // Example total pages
    const [query, setQuery] = useState(''); // The full query string
    const [suggestions, setSuggestions] = useState([]); // List of suggestions
    const [highlightedIndex, setHighlightedIndex] = useState(-1); // For keyboard navigation in suggestions
    const inputRef = useRef(null); // For handling cursor focus

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const onChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    // Handle text input and show suggestions
    const onInputChange = (e) => {
        const inputText = e.target.value;
        setQuery(inputText);

        const currentWord = inputText.split(' ').pop(); // Get the current word for suggestions

        // If the current word matches a field prefix, show suggestions
        if (currentWord.length > 0) {
            const filteredSuggestions = indexFields.filter((field) =>
                field.toLowerCase().startsWith(currentWord.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
            setHighlightedIndex(-1); // Reset highlighted index on new input
        } else {
            setSuggestions([]);
        }
    };

    // Handle suggestion selection
    const onSuggestionClick = (suggestion) => {
        const words = query.split(' ');
        words.pop(); // Remove the current word being typed
        const newQuery = `${words.join(' ')} ${suggestion}: `;
        setQuery(newQuery);
        setSuggestions([]); // Hide suggestions after selection
        inputRef.current.focus(); // Focus back to the input field
    };

    // Handle keyboard events for Enter key and arrow navigation
    const onKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setHighlightedIndex((prev) =>
                prev === suggestions.length - 1 ? 0 : prev + 1
            );
        } else if (e.key === 'ArrowUp') {
            setHighlightedIndex((prev) =>
                prev === 0 ? suggestions.length - 1 : prev - 1
            );
        } else if (e.key === 'Enter' && highlightedIndex !== -1) {
            // Select the currently highlighted suggestion
            onSuggestionClick(suggestions[highlightedIndex]);
        }
    };

    // Highlight field names in the query using regex
    const getHighlightedQuery = () => {
        const regex = new RegExp(`(${indexFields.join('|')}):`, 'gi');
        const parts = query.split(regex);

        return parts.map((part, index) => (
            regex.test(part) ? (
                <strong key={index} style={{ color: '#0073e6' }}>{part}</strong>
            ) : (
                <span key={index}>{part}</span>
            )
        ));
    };

    // Submit the final search query
    const onSubmit = () => {
        console.log('Search Query:', query);
    };

    return (
        <>
            {/* Search and Pagination Controls */}
            <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <EuiTitle size="s">
                        <h2>Search</h2>
                    </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                    {/* Input field with suggestion dropdown */}
                    <div style={{ position: 'relative' }}>
                        {/* Render the highlighted query */}
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                                pointerEvents: 'none',
                                color: 'transparent',
                                whiteSpace: 'pre-wrap',
                                fontSize: '1rem',
                            }}
                        >
                            {getHighlightedQuery()}
                        </div>

                        {/* Actual input field */}
                        <EuiFieldText
                            inputRef={inputRef}
                            placeholder="Start typing a query..."
                            value={query}
                            onChange={onInputChange}
                            onKeyDown={onKeyDown}
                            fullWidth
                            style={{ position: 'relative', zIndex: 2 }}
                        />

                        {/* Suggestions dropdown */}
                        {suggestions.length > 0 && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    width: '100%',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                    zIndex: 10,
                                    backgroundColor: 'white', // Ensures suggestions are visible
                                }}
                            >
                                {suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        onClick={() => onSuggestionClick(suggestion)}
                                        style={{
                                            padding: '8px',
                                            cursor: 'pointer',
                                            backgroundColor:
                                                highlightedIndex === index ? '#0073e6' : "#1D1E24",
                                            color: highlightedIndex === index ? 'white' : 'white',
                                            borderBottom: '1px solid #eee',
                                        }}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="l" />

            {/* Pagination Top */}
            <EuiPagination
                pageCount={totalPages}
                activePage={currentPage}
                onPageClick={onPageChange}
            />

            <EuiSpacer size="m" />

            {/* Document List */}
            {documentsData.map((document) => (
                <EuiPanel key={document.id} paddingSize="m">
                    <EuiText>
                        <strong>Document id: {document.id}</strong>
                    </EuiText>
                    <EuiSpacer size="s" />

                    {document.fields.map((field) => (
                        <EuiFlexGroup key={field.name} gutterSize="s" alignItems="center">
                            <EuiFlexItem grow={false}>
                                <EuiIcon type="tag" />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiText>{field.name}</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiIcon type="arrowRight" />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>{field.value}</EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    ))}
                    <EuiFlexGroup justifyContent="flexEnd">
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon iconType="inspect" aria-label="Inspect document" />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon iconType="sortable" aria-label="Sort document" />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            ))}

            <EuiSpacer size="l" />

            {/* Pagination Bottom */}
            <EuiPagination
                pageCount={totalPages}
                activePage={currentPage}
                onPageClick={onPageChange}
            />
        </>
    );
};

export default IndexDocumentsView;
