import React, { useState } from 'react';
import {
  EuiSideNav,
  EuiAvatar,
} from '@elastic/eui';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const SidebarNavigation = () => {
  const [isIndicesOpen, setIsIndicesOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { deploymentId } = useParams(); // Get deploymentId from URL

  const toggleIndices = () => {
    navigate(`/deployments/view/${deploymentId}/app/indices`);
    setIsIndicesOpen(!isIndicesOpen);
  };

  const items = [
    {
      name: 'Search',
      id: 'search',
      icon: <EuiAvatar size="l" name="Search" />,
      isSelected: location.pathname === `/deployments/view/${deploymentId}/app/search`,
    },
    {
      name: 'Content',
      id: 'content',
      items: [
        {
          name: 'Indices',
          id: 'indices',
          isOpen: isIndicesOpen,
          onClick: toggleIndices,
          isSelected: location.pathname === `/deployments/view/${deploymentId}/app/indices`,
          items: [
            // Add sub-items here if needed
          ],
        },
        {
          name: 'Connectors',
          id: 'connectors',
          onClick: () => navigate(`/deployments/view/${deploymentId}/app/connectors`),
          isSelected: location.pathname.includes(`/deployments/view/${deploymentId}/app/connectors`),
        },
        // {
        //   name: 'Web crawlers',
        //   id: 'web_crawlers',
        //   href: '#',
        // },
      ],
    },
    {
      name: 'Build',
      id: 'build',
      items: [
        {
          name: 'Playground',
          id: 'playground',
          onClick: () => navigate(`/deployments/view/${deploymentId}/app/console`),
          isSelected: location.pathname === `/deployments/view/${deploymentId}/app/console`,
        },
        // Add more items here if needed
      ],
    },
    // Add more sections here if needed
  ];

  return (
    <div style={{ width: 250 }}>
      <EuiSideNav
        mobileTitle="Navigate"
        toggleOpenOnMobile={() => { }}
        isOpenOnMobile={true}
        items={items}
        style={{ width: 250 }}
      />
    </div>
  );
};

export default SidebarNavigation;