import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../utils/user-context';
import { getLoggedInUserAndNewToken, logoutUser } from '../utils/api';

const PrivateRoute = ({ children }) => {
    const { state, dispatch } = useUserContext();
    const location = useLocation();

    useEffect(() => {
        const checkUser = async () => {
            try {
                const response = await getLoggedInUserAndNewToken();
                if (!response.data.user) {
                    throw new Error('User not authenticated');
                }
                dispatch({ type: 'SET_USER', payload: response.data.user });
            } catch (error) {
                logoutUser();
                dispatch({ type: 'LOGOUT_USER' });
                window.location.href = `/login?redirect=${location.pathname}`;
            }
        };

        if (!state.user) {
            checkUser();
        }
    }, [state.user, dispatch, location.pathname]);

    return state.user ? children : null;
};

export default PrivateRoute;