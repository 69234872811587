import React, { useEffect, useState } from 'react';
import {
    EuiPopover,
    EuiAvatar,
    EuiContextMenu,
    EuiSwitch,
} from '@elastic/eui';
import { useUserContext } from '../utils/user-context';
import { logoutUser } from '../utils/api';
import { useNavigate } from 'react-router-dom';

const UserPopover = () => {
    const { state, dispatch } = useUserContext();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isDark, setIsDark] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );
    const navigate = useNavigate();

    // Listen for theme changes
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handler = (e) => setIsDark(e.matches);
        
        mediaQuery.addListener(handler);
        return () => mediaQuery.removeListener(handler);
    }, []);

    const onPopoverButtonClick = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const closePopover = () => {
        setIsPopoverOpen(false);
    };

    const handleLogout = () => {
        logoutUser();
        closePopover();
        navigate('/login');
    };

    const popoverButton = (
        <EuiAvatar
            size="m"
            name={state.user?.name || 'User'}
            color="#DB1374"
            onClick={onPopoverButtonClick}
            aria-label="Account menu"
        />
    );

    const items = [
        // {
        //     name: 'Profile',
        //     icon: 'user',
        //     onClick: () => navigate('/profile'),
        // },
        // {
        //     name: 'Organization',
        //     icon: 'gear',
        //     onClick: () => navigate('/organization'),
        // },
        // {
        //     name: 'Theme',
        //     icon: isDark ? 'moon' : 'sun',
        //     onClick: () => {}, // Theme switching would go here
        // },
        {
            name: 'Log out',
            icon: 'exit',
            onClick: handleLogout,
        },
    ];

    const popoverContent = (
        <EuiContextMenu
            initialPanelId={0}
            panels={[
                {
                    id: 0,
                    title: `${state.user?.firstname} ${state.user?.lastname}`,
                    items: items.map((item) => ({
                        name: item.name,
                        icon: item.icon,
                        onClick: item.onClick,
                    })),
                },
            ]}
        />
    );

    return (
        <EuiPopover
            button={popoverButton}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            anchorPosition="downRight"
            style={{
                marginLeft: "auto",
                marginTop: "0.2rem"
            }}
        >
            {popoverContent}
        </EuiPopover>
    );
};

export default UserPopover;