let addGlobalErrorHandler = null;
let addGlobalSuccessHandler = null;

export const setGlobalErrorHandler = (handler) => {
  addGlobalErrorHandler = handler;
};

export const setGlobalSuccessToastHandler = (handler) => {
  addGlobalSuccessHandler = handler;
};

export const getGlobalErrorHandler = () => addGlobalErrorHandler;
export const getGlobalSuccessToastHandler = () => addGlobalSuccessHandler;
