// OverviewTab.js
import React from 'react';
import {
    EuiText,
    EuiSpacer,
    EuiBadge,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiButtonIcon,
    EuiCopy,
    EuiCallOut,
    EuiIcon
} from '@elastic/eui';

const CustomIconUrl = {
    'Azure Blob Storage': 'https://img.icons8.com/color/48/azure.png',
    Kafka: 'https://img.icons8.com/?size=100&id=fOhLNqGJsUbJ&format=png&color=000000',
    'Google Cloud Storage': 'https://img.icons8.com/?size=100&id=gcp_icon&format=png',
    Outlook: 'https://img.icons8.com/?size=100&id=outlook_icon&format=png',
};

const OverviewTab = ({ connector }) => (
    <>

        {/* Error and Warning Panels */}
        <EuiCallOut
            title="Your connector has reported an error"
            color="danger"
            iconType="alert"
            style={{ backgroundColor: '#661515' }}
        >
            <p>Field validation errors: 'Account name' cannot be empty.; 'Account key' cannot be empty.; 'Blob endpoint' cannot be empty.; 'List of containers' cannot be empty.</p>
        </EuiCallOut>

        <EuiSpacer size="m" />

        <EuiCallOut
            title="Connector has no attached index"
            color="warning"
            iconType="iInCircle"
            style={{ backgroundColor: '#5c2b29' }}
        >
            <p>You won't be able to start syncing content until your connector is attached to an index.</p>
            <EuiButton color="danger" style={{ marginTop: '8px' }}>Attach index</EuiButton>
        </EuiCallOut>

        <EuiSpacer size="l" />

        {/* Information Panels */}
        <EuiFlexGroup>
            {/* Connector Panel */}
            <EuiFlexItem>
                <div style={{
                    border: '1px solid #333',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    width: '100%',
                    maxWidth: '400px',
                    margin: '0 auto',
                }}>
                    <div style={{
                        backgroundColor: '#1E1E1E',
                        minHeight: "8rem",

                        padding: '16px',
                        // display: 'flex',
                        // justifyContent: 'space-between',
                    }}>
                        <EuiText size="xs">
                            <strong>Connector</strong>
                        </EuiText>
                        <div style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={CustomIconUrl[connector.type]}
                                    alt={`${connector.type} icon`}
                                    style={{ width: '32px', height: '32px', marginRight: '8px' }}
                                />
                                <EuiText size="m">
                                    <strong>{connector.type}</strong>
                                </EuiText>
                            </div>
                            <EuiBadge color="warning">Connector Failure</EuiBadge>
                        </div>
                    </div>
                    {/* Bottom Section */}
                    <div style={{
                        backgroundColor: '#2C2C2C',
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <EuiText size="xs" color="subdued">
                                <strong>ID:</strong>
                            </EuiText>
                            <EuiSpacer size="xs" />
                            <EuiText size="xs" color="accent" style={{ marginLeft: '8px' }}>
                                {connector.connector_id}
                            </EuiText>
                            <EuiCopy textToCopy={connector.connector_id}>
                                {(copy) => (
                                    <EuiButtonIcon
                                        onClick={copy}
                                        iconType="copyClipboard"
                                        aria-label="Copy ID"
                                        style={{ marginLeft: '8px' }}
                                    />
                                )}
                            </EuiCopy>
                        </div>
                        <EuiButton size="s" fill color="primary">Configure</EuiButton>
                    </div>
                </div>


            </EuiFlexItem>

            {/* Attached Index Panel */}
            <EuiFlexItem>
                <div style={{
                    border: '1px solid #333',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    width: '100%',
                    maxWidth: '400px',
                    margin: '0 auto',
                }}>
                    <div style={{
                        backgroundColor: '#1E1E1E',
                        padding: '16px',
                        // display: 'flex',
                        // justifyContent: 'space-between',
                        minHeight: "8rem"

                    }}>
                        <EuiText size="xs">
                            <strong>Attached Index</strong>
                        </EuiText>
                        <div style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!connector?.index_name && <EuiText size="s" color="danger">
                                    No index attached yet
                                </EuiText>}

                                {connector?.index_name && <EuiText size="l" color="success">
                                    <strong>{connector.index_name}</strong>
                                </EuiText>}

                            </div>
                        </div>
                    </div>
                    {/* Bottom Section */}
                    <div style={{
                        backgroundColor: '#2C2C2C',
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <EuiFlexGroup alignItems="center" gutterSize="s">
                                <EuiFlexItem grow={false}>
                                    <EuiIcon type="document" />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiText size="s">0 Documents</EuiText>
                                </EuiFlexItem>

                            </EuiFlexGroup>
                        </div>
                        <EuiButton size="s" color="primary">See documents</EuiButton>
                    </div>
                </div>
            </EuiFlexItem>

            {/* Pipelines Panel */}
            <EuiFlexItem>
                <div style={{
                    border: '1px solid #333',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    width: '100%',
                    maxWidth: '400px',
                    margin: '0 auto',
                }}>
                    <div style={{
                        backgroundColor: '#1E1E1E',
                        padding: '16px',
                        // display: 'flex',
                        // justifyContent: 'space-between',
                        minHeight: "8rem"

                    }}>
                        <EuiText size="xs">
                            <strong>Pipelines</strong>
                        </EuiText>
                        <div style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <EuiBadge color="default">{connector.pipelines || 'ent-search-generic-ingestion'}</EuiBadge>


                            </div>
                        </div>
                    </div>
                    {/* Bottom Section */}
                    <div style={{
                        backgroundColor: '#2C2C2C',
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>

                        <EuiButton size="s" color="text">Manage pipelines</EuiButton>
                    </div>
                </div>

            </EuiFlexItem>
        </EuiFlexGroup>

    </>
);

export default OverviewTab;