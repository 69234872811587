import React, { createContext, useContext, useState } from 'react';
import { EuiGlobalToastList } from '@elastic/eui';
import { setGlobalErrorHandler, setGlobalSuccessToastHandler } from '../utils/global-toast';

const GlobalToastContext = createContext();

export const GlobalToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addGlobalError = (title, color = 'danger') => {
        setToasts([...toasts, { id: Date.now(), title, color }]);
    };

    const addGlobalSuccess = (title) => {
        setToasts((currentToasts) => [
            ...currentToasts,
            {
                id: new Date().getTime().toString(),
                title,
                color: 'success',
            },
        ]);
    };

    const clearAllToasts = () => {
        setToasts([]);  // This will clear all the toasts by setting the state to an empty array
    };


    setGlobalErrorHandler(addGlobalError);
    setGlobalSuccessToastHandler(addGlobalSuccess);

    return (
        <GlobalToastContext.Provider value={{ addGlobalError, addGlobalSuccess }}>
            {children}
            {toasts.length > 0 && <EuiGlobalToastList
                toasts={toasts}
                dismissToast={(removedToast) => {
                    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
                }}
                toastLifeTimeMs={5000}
                onClearAllToasts={clearAllToasts}
                style={{ top: 16, right: 16, position: 'fixed' }}
            />}
        </GlobalToastContext.Provider>
    );
};

export const useGlobalToast = () => useContext(GlobalToastContext);
