import React, { useState } from 'react';
import {
  Comparators,
  EuiBasicTable,
  EuiLink,
  EuiHealth,
  EuiSpacer,
  EuiButton
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';

const IndexListTable = ({ indices, deploymentId }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      field: 'index_name',
      name: 'Index name',
      truncateText: true,
      sortable: true,
      render: (indexName, item) => (
        <EuiLink href={`/deployments/view/${deploymentId}/app/indices/details/${item.uuid}/overview`} target="_blank">
          {indexName}
        </EuiLink>
      ),
    },
    {
      field: 'index_state',
      name: 'Index state',
      render: (state) => (
        <EuiButton size="s" color={state === 'CREATED' ? 'success' : 'danger'}>
          {state}
        </EuiButton>
      ),
    },
    {
      field: 'use_dynamic_mapping',
      name: 'Mapping',
      render: (use_dynamic_mapping) => (
        <EuiButton size="s" color={use_dynamic_mapping == true ? 'danger' : 'primary'}>
          {use_dynamic_mapping ? "Dynamic" : "User Defined"}
        </EuiButton>
      ),
    },
    // {
    //   field: 'index_state',
    //   name: 'Index state',
    //   truncateText: true,
    //   render: (state) => (
    //     <EuiHealth color={state === 'HEALTHY' ? 'success' : 'danger'}>
    //       {state}
    //     </EuiHealth>
    //   ),
    // },
    {
      field: 'docs_count',
      name: 'Docs count',
    },

    {
      name: 'Actions',
      actions: [
        {
          name: 'View',
          description: 'View index',
          icon: 'eye',
          type: 'icon',
          onClick: (item) => navigate(`/deployments/view/${deploymentId}/app/indices/details/${item.uuid}/overview`),
        },
        {
          name: 'Delete',
          description: 'Delete index',
          icon: 'trash',
          color: 'danger',
          type: 'icon',
          onClick: () => { },
        },
      ],
    },
  ];

  const onSelectionChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const selection = {
    selectable: (index) => index.ingestion_state,
    selectableMessage: (selectable, index) =>
      !selectable
        ? `${index.index_name} is currently offline`
        : `Select ${index.index_name}`,
    onSelectionChange,
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [sortField, setSortField] = useState('index_name');
  const [sortDirection, setSortDirection] = useState('asc');

  const onTableChange = ({ page, sort }) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page;
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    }
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  const findIndices = (indices, pageIndex, pageSize, sortField, sortDirection) => {
    let items;
    if (sortField) {
      items = indices
        .slice(0)
        .sort(
          Comparators.property(sortField, Comparators.default(sortDirection))
        );
    } else {
      items = indices;
    }
    let pageOfItems;
    if (!pageIndex && !pageSize) {
      pageOfItems = items;
    } else {
      const startIndex = pageIndex * pageSize;
      pageOfItems = items.slice(
        startIndex,
        Math.min(startIndex + pageSize, indices.length)
      );
    }
    return {
      pageOfItems,
      totalItemCount: indices.length,
    };
  };

  const { pageOfItems, totalItemCount } = findIndices(
    indices,
    pageIndex,
    pageSize,
    sortField,
    sortDirection
  );

  const pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: totalItemCount,
    pageSizeOptions: [3, 5, 8],
  };

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  return (
    <>
      <EuiSpacer size="l" />
      <EuiBasicTable
        items={pageOfItems}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        selection={selection}
        onChange={onTableChange}
      />
    </>
  );
};

export default IndexListTable;