import React, { useEffect, useState } from 'react';
import {
    EuiFieldText,
    EuiFieldPassword,
    EuiButton,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiText,
    EuiLink,
    EuiHorizontalRule,
    EuiPageTemplate,
    EuiPageBody
} from '@elastic/eui';
import { loginUser } from '../utils/api'; // Import loginUser
import { useNavigate, useLocation } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const redirectPath = new URLSearchParams(location.search).get('redirect') || '/deployments';

    // Add theme detection hook
    const [isDark, setIsDark] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    // Listen for theme changes
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handler = (e) => setIsDark(e.matches);

        mediaQuery.addListener(handler);
        return () => mediaQuery.removeListener(handler);
    }, []);

    const handleLogin = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await loginUser({ email, password }); // Use loginUser
            if (response.data.user) {
                navigate(redirectPath);
            }
        } catch (err) {
            console.log(err);
            setError('Login failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <EuiPageTemplate
            restrictWidth={true}
            template="empty"
            style={{}}
        >
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' className='' style={{ padding: '0.5rem 0' }}>
                <EuiFlexItem
                    style={{
                        padding: "1rem",
                    }}
                >
                    <img
                        src={isDark ? "/ssphere_white.png" : "/ssphere_black.png"}
                        alt="SearchSphere Logo"
                        style={{
                            height: '35px',
                            width: '160px',
                            marginRight: '8px'
                        }}
                        onClick={() => navigate('/')}
                    />
                </EuiFlexItem>
                {/* <EuiFlexItem grow={false} style={{ padding: '0.5rem' }}>
                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                        <EuiFlexItem grow={false} >
                            <EuiText>
                                <strong>Don't have an account?</strong>
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} >
                            <EuiButton onClick={() => navigate('/signup')}>
                                Sign up
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem> */}
            </EuiFlexGroup>

            <EuiPageBody>
                <EuiForm component="form" onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                    <EuiFlexGroup direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                        <EuiFlexItem grow={false} style={{ width: '300px' }}>
                            <EuiText textAlign="center">
                                <h2>Log in to your account</h2>
                            </EuiText>
                            <EuiSpacer size="l" />

                            <EuiFormRow label="Email" isInvalid={!!error} error={error}>
                                <EuiFieldText
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    aria-label="Email"
                                />
                            </EuiFormRow>

                            <EuiSpacer size="m" />

                            <EuiFormRow label="Password" isInvalid={!!error} error={error}>
                                <EuiFieldPassword
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    aria-label="Password"
                                    type="dual"
                                />
                            </EuiFormRow>

                            <EuiSpacer size="l" />

                            <EuiButton
                                type="submit"
                                fill
                                isLoading={loading}
                                disabled={!email || !password}
                                style={{ width: '100%' }}
                            >
                                Log in
                            </EuiButton>

                            <EuiSpacer size="l" />
                            {/* 
                            <GoogleLoginButton navigate={navigate} setError={setError} />

                            <EuiSpacer size="m" />

                            <EuiButton
                                iconType="logoMicrosoft"
                                onClick={() => { }}
                                style={{ width: '100%' }}
                            >
                                Log in with Microsoft
                            </EuiButton> */}

                            <EuiSpacer size="l" />

                            <EuiText size="s" textAlign="center">
                                <p>
                                    By logging in, you acknowledge that you've read and agree to our <EuiLink href="#">Terms of Service</EuiLink> and <EuiLink href="#">Privacy Statement</EuiLink>.
                                </p>
                            </EuiText>

                            {/* <EuiHorizontalRule size="full" />

                            <EuiText textAlign="center" size="s">
                                <p>OR</p>
                            </EuiText>

                            <EuiText textAlign="center" size="s">
                                <p>Subscribe via a cloud marketplace for simplified billing</p>
                            </EuiText>

                            <EuiSpacer size="m" />

                            <EuiFlexGroup justifyContent="center" gutterSize="s">
                                <EuiFlexItem grow={false}>
                                    <EuiButton iconType="logoAWS" onClick={() => { }}>
                                        Amazon Web Services
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton iconType="logoGCP" onClick={() => { }}>
                                        Google Cloud
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton iconType="logoAzure" onClick={() => { }}>
                                        Microsoft Azure
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup> */}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiForm>
            </EuiPageBody>
        </EuiPageTemplate>
    );
};


const GoogleLoginButton = ({ navigate, setError }) => {
    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            console.log('Google login success:', response);
            try {
                const token = response.credential;
                const userResponse = await loginUser({ token, provider: 'google' });
                if (userResponse.user) {
                    navigate('/deployments');
                }
            } catch (err) {
                console.log(err);
                setError('Google login failed. Please try again.');
            }
        },
        onError: (error) => {
            console.log('Google login failure:', error);
            setError('Google login failed. Please try again.');
        }
    });

    return (
        <EuiButton
            iconType="logoGoogleG"
            onClick={handleGoogleLogin}
            style={{ width: '100%' }}
        >
            Sign in with Google
        </EuiButton>
    );
};


export default LoginPage;