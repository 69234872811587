import React, { useEffect, useState } from 'react';
import {
    EuiPageTemplate,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiTitle,
    EuiNotificationBadge,
    EuiSpacer,
    EuiBadge,
    EuiButton,
    EuiPanel,
    EuiSearchBar,
    EuiTable,
    EuiTableBody,
    EuiTableRow,
    EuiTableRowCell,
    EuiTableHeader,
    EuiTableHeaderCell,
    EuiIcon,
    EuiPagination,
    EuiButtonIcon,
    EuiHorizontalRule,
    EuiConfirmModal,
    EuiOverlayMask,
} from '@elastic/eui';
import { useNavigate, useParams } from 'react-router-dom';
import { listDeploymentConnectors } from '../../utils/api';

const CustomIconUrl = {
    Kafka: 'https://img.icons8.com/?size=100&id=fOhLNqGJsUbJ&format=png&color=000000'
};

const ConnectorPage = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const { deploymentId } = useParams();
    const [connectorData, setConnectorData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedConnector, setSelectedConnector] = useState(null);
    const limit = 20;

    const navigate = useNavigate();

    useEffect(() => {
        const fetchConnectors = async () => {
            setLoading(true);
            try {
                const response = await listDeploymentConnectors({ deploymentId, page: currentPage + 1, limit });
                setConnectorData(response?.data?.connectors);
                setPageCount(Math.ceil(response?.data?.pagination?.total / limit));

                if (response?.data?.connectors.length === 0) {
                    navigate(`/deployments/view/${deploymentId}/app/connectors/create`);
                }
            } catch (error) {
                console.error('Error fetching indexes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchConnectors();
    }, [deploymentId, currentPage]);

    const handleCreate = () => {
        console.log("Create connector");
        navigate(`/deployments/view/${deploymentId}/app/connectors/create`);
    };

    const handlePageChange = (page) => setCurrentPage(page);

    const handleDeleteClick = (connector) => {
        setSelectedConnector(connector);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedConnector(null);
    };

    const confirmDelete = async () => {
        if (selectedConnector) {
            console.log("Deleting connector:", selectedConnector.connector_id);
            // Add delete API call here, e.g.:
            // await deleteConnector(selectedConnector.connector_id);
            setConnectorData(connectorData.filter(connector => connector.connector_id !== selectedConnector.connector_id));
            closeModal();
        }
    };

    const handleViewClick = (connector) => {
        navigate(`/deployments/view/${deploymentId}/app/connectors/${connector.uuid}`);
    };


    return (
        <>
            {/* Header */}
            <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                <EuiFlexItem grow={false}>
                    <EuiTitle size="l">
                        <h1>SearchSphere connectors</h1>
                    </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton iconType="plus" onClick={handleCreate} fill disabled={false}>New Connector</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="l" />

            {/* Connector Summary */}
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText>
                            <h3>Connector summary</h3>
                            <p>0 connector</p>
                            <EuiBadge color="success">0 Connected</EuiBadge>
                            <EuiBadge color="warning">0 Incomplete</EuiBadge>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiText>
                            <h3>Syncs status</h3>
                            <p>0 running syncs</p>
                            <EuiBadge color="default">0 Running syncs</EuiBadge>
                            <EuiBadge color="default">0 Idle syncs</EuiBadge>
                            <EuiBadge color="default">0 Orphaned syncs</EuiBadge>
                            <EuiBadge color="danger">0 Sync errors</EuiBadge>
                        </EuiText>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="l" />

            {/* Available Connectors */}
            <EuiTitle size="m">
                <h2>Available connectors</h2>
            </EuiTitle>

            <EuiSpacer size="m" />

            {/* Search Bar */}
            <EuiSearchBar box={{ placeholder: 'Filter connectors' }} />

            <EuiSpacer size="m" />

            {/* Table of connectors */}
            <EuiTable>
                <EuiTableHeader>
                    <EuiTableHeaderCell>Connector name</EuiTableHeaderCell>
                    <EuiTableHeaderCell>Index name</EuiTableHeaderCell>
                    <EuiTableHeaderCell>Connector type</EuiTableHeaderCell>
                    <EuiTableHeaderCell>Status</EuiTableHeaderCell>
                    <EuiTableHeaderCell>Actions</EuiTableHeaderCell>
                </EuiTableHeader>

                <EuiTableBody>
                    {connectorData.map((connector, index) => (
                        <EuiTableRow key={index}>
                            <EuiTableRowCell>
                                <a href="#">{connector.connector_id}</a>
                            </EuiTableRowCell>
                            <EuiTableRowCell>{connector.index_name}</EuiTableRowCell>
                            <EuiTableRowCell>
                                <img src={CustomIconUrl[connector.type]} style={{ width: "15px" }} /> {connector.type}
                            </EuiTableRowCell>
                            <EuiTableRowCell>
                                <EuiBadge color={connector.enabled ? 'success' : 'danger'}>
                                    {connector.enabled ? 'ENABLED' : 'DISABLED'}
                                </EuiBadge>
                            </EuiTableRowCell>
                            <EuiTableRowCell>
                                <EuiButtonIcon iconType="trash" aria-label="Delete" onClick={() => handleDeleteClick(connector)} />
                                <EuiButtonIcon iconType="eye" aria-label="View" onClick={() => handleViewClick(connector)} />
                            </EuiTableRowCell>
                        </EuiTableRow>
                    ))}
                </EuiTableBody>
            </EuiTable>

            {/* Pagination */}
            <EuiSpacer size="l" />
            <EuiPagination
                pageCount={pageCount}
                activePage={currentPage}
                onPageClick={handlePageChange}
            />

            {/* Delete Confirmation Modal */}
            {isModalVisible && (
                <EuiOverlayMask>
                    <EuiConfirmModal
                        title="Confirm delete"
                        onCancel={closeModal}
                        onConfirm={confirmDelete}
                        cancelButtonText="No, don't delete"
                        confirmButtonText="Yes, delete"
                        buttonColor="danger"
                        defaultFocusedButton="confirm"
                    >
                        <p>Are you sure you want to delete the connector <strong>{selectedConnector.connector_id}</strong> ? This action cannot be undone.</p>
                    </EuiConfirmModal>
                </EuiOverlayMask>
            )}
        </>
    );
};

export default ConnectorPage;