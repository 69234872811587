import React, { useState } from 'react';
import {
    EuiPageTemplate,
    EuiText,
    EuiFieldText,
    EuiFormRow,
    EuiButton,
    EuiProgress,
    EuiRadioGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSelect,
    EuiSpacer,
    EuiForm
} from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_dark.css'; // Dark theme similar to screenshots

const MultistepWelcomeForm = () => {
    const [step, setStep] = useState(0);
    const [fullName, setFullName] = useState('');
    const [company, setCompany] = useState('');
    const [familiarity, setFamiliarity] = useState('');
    const [goal, setGoal] = useState('');
    const [useCase, setUseCase] = useState('');
    const [provider, setProvider] = useState('AWS');
    const [region, setRegion] = useState('ap-south-1');

    // Navigation functions
    const nextStep = () => setStep((prev) => prev + 1);
    const prevStep = () => setStep((prev) => Math.max(prev - 1, 0));

    // Progress bar values
    const progressSteps = [
        'Step 1: Welcome',
        'Step 2: Familiarity',
        'Step 3: Trial Goal',
        'Step 4: Use Case',
        'Step 5: Data Storage',
        'Step 6: Setup',
    ];

    const stepComponents = [
        <StepWelcome fullName={fullName} setFullName={setFullName} company={company} setCompany={setCompany} />,
        <StepFamiliarity familiarity={familiarity} setFamiliarity={setFamiliarity} />,
        <StepTrialGoal goal={goal} setGoal={setGoal} />,
        <StepUseCase useCase={useCase} setUseCase={setUseCase} />,
        <StepDataStorage provider={provider} setProvider={setProvider} region={region} setRegion={setRegion} />,
        <StepSetup />,
    ];

    return (
        <EuiPageTemplate
            restrictWidth={true}
            template="empty"
            style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <EuiFlexGroup justifyContent="spaceAround" style={{ width: '100%' }}>
                {/* Main form content */}
                <EuiFlexItem grow={false} style={{ maxWidth: '400px' }}>
                    <EuiProgress value={((step + 1) / progressSteps.length) * 100} max={100} size="l" color="primary" />
                    <EuiSpacer size="l" />
                    {stepComponents[step]}
                    <EuiSpacer size="xxl" />
                    <div className='mt-4'>
                        <EuiButton color="text" onClick={prevStep} disabled={step === 0} style={{ marginRight: '10px' }}>
                            Go Back
                        </EuiButton>
                        {step < stepComponents.length - 1 ? (
                            <EuiButton
                                color='success'
                                fill
                                onClick={nextStep}
                                disabled={step === 0 && (!fullName || !company)}
                            >
                                Continue
                            </EuiButton>
                        ) : null}
                    </div>
                </EuiFlexItem>

            </EuiFlexGroup>
        </EuiPageTemplate>
    );
};

// Step 1: Welcome
const StepWelcome = ({ fullName, setFullName, company, setCompany }) => (
    <>
        <EuiText>
            <h2>Welcome to SearchSphere!</h2>
            <p>Let’s set up your free trial experience. This will only take a minute.</p>
        </EuiText>
        <EuiSpacer size="m" />
        <EuiForm component="form">
            <EuiFormRow label="Full name">
                <EuiFieldText value={fullName} onChange={(e) => setFullName(e.target.value)} />
            </EuiFormRow>
            <EuiSpacer size="xl" />

            <EuiFormRow label="Company">
                <EuiFieldText value={company} onChange={(e) => setCompany(e.target.value)} />
            </EuiFormRow>
        </EuiForm>
    </>
);

// Step 2: Familiarity with Elastic
const StepFamiliarity = ({ familiarity, setFamiliarity }) => {
    const options = [
        { id: 'new', label: 'I am new to Elastic' },
        { id: 'experience', label: 'I have some experience with Elastic' },
        { id: 'expert', label: 'I am an expert' },
    ];
    return (
        <>
            <EuiText>
                <h2>How familiar are you with Full Text Search?</h2>
            </EuiText>
            <EuiSpacer size="m" />
            <EuiRadioGroup
                options={options}
                idSelected={familiarity}
                onChange={(id) => setFamiliarity(id)}
            />
        </>
    );
};

// Step 3: Primary Goal
const StepTrialGoal = ({ goal, setGoal }) => {
    const options = [
        { id: 'project', label: 'Evaluate Elastic for my project or use case' },
        { id: 'migrate', label: 'Migrate an existing Elasticsearch environment' },
        { id: 'pricing', label: 'Get pricing information' },
        { id: 'learn', label: 'Learn more about Elastic' },
        { id: 'other', label: 'Do something else' },
    ];
    return (
        <>
            <EuiText>
                <h2>What's your primary goal during the trial?</h2>
            </EuiText>
            <EuiSpacer size="m" />
            <EuiRadioGroup
                options={options}
                idSelected={goal}
                onChange={(id) => setGoal(id)}
            />
        </>
    );
};

// Step 4: Use Case
const StepUseCase = ({ useCase, setUseCase }) => {
    const options = [
        { id: 'search', label: 'Search' },
        { id: 'observability', label: 'Observability' },
        { id: 'security', label: 'Security' },
    ];
    return (
        <>
            <EuiText>
                <h2>Which use case are you looking to try first?</h2>
                <p>We'll help you get set up with a relevant Elastic solution.</p>
            </EuiText>
            <EuiSpacer size="m" />
            <EuiRadioGroup
                options={options}
                idSelected={useCase}
                onChange={(id) => setUseCase(id)}
            />
        </>
    );
};

// Step 5: Data Storage
const StepDataStorage = ({ provider, setProvider, region, setRegion }) => {
    const providers = [
        { value: 'AWS', text: 'Amazon Web Services' },
        { value: 'GCP', text: 'Google Cloud' },
        { value: 'Azure', text: 'Microsoft Azure' },
    ];

    const regions = [
        { value: 'ap-south-1', text: 'Mumbai (ap-south-1)' },
        { value: 'us-east-1', text: 'N. Virginia (us-east-1)' },
    ];

    return (
        <>
            <EuiText>
                <h2>Lastly, where would you like your data stored?</h2>
                <p>We'll host your Elastic Cloud data in your preferred region.</p>
            </EuiText>
            <EuiSpacer size="m" />
            <EuiFormRow label="Provider">
                <EuiSelect
                    options={providers}
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                />
            </EuiFormRow>
            <EuiFormRow label="Region">
                <EuiSelect
                    options={regions}
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                />
            </EuiFormRow>
        </>
    );
};

// Step 6: Setup Page
const StepSetup = () => (
    <>
        <EuiText>
            <h2>Hang tight! We are setting up your environment.</h2>
            <p>This process usually takes 3-5 minutes.</p>
        </EuiText>
        {/* Placeholder for video */}
        <EuiSpacer size="m" />
        <div style={{ width: '100%', height: '250px', background: '#1D1E24', borderRadius: '8px' }}>
            <EuiText style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <EuiButton iconType="play" fill>Watch Setup Video</EuiButton>
            </EuiText>
        </div>
    </>
);

export default MultistepWelcomeForm;
