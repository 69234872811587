import React, { useState, useRef, useEffect } from 'react';
import {
    EuiPage,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiText,
    EuiButton,
    EuiSpacer,
    EuiPanel,
    EuiPagination,
    EuiIcon,
    EuiBadge,
    EuiLink,
    EuiCallOut,
    EuiDescriptionList
} from '@elastic/eui';
import ReactJson from 'react-json-view';
import { searchInAnIndex } from '../utils/api';
import { useNavigate } from 'react-router-dom';

const suggestionsData = [
    "_id",
    "bucketId",
    "cloudProvider",
    "fileExtension",
    "fileName",
    "fileSize",
    "fileType",
    "fileUrl",
    "fullPath",
    "indexedAt",
    "lastModified",
    "sensitive",
];

const useSystemTheme = () => {
    const [isDark, setIsDark] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handler = (e) => setIsDark(e.matches);

        mediaQuery.addListener(handler);
        return () => mediaQuery.removeListener(handler);
    }, []);

    return isDark;
};


const DemoSearchPage = ({ indexId }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [events, setEvents] = useState([]);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);

    const inputRef = useRef(null);
    const isDarkMode = useSystemTheme();
    const navigate = useNavigate();


    const handleInputChange = (e) => {
        const query = e.target.value;
        console.log({ query })

        if (query != undefined && query != null) {
            setSearchQuery(query);

        }

        if (query.length > 0) {
            const filteredSuggestions = suggestionsData.filter((suggestion) =>
                suggestion?.toLowerCase()?.startsWith(query.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        if (suggestion) {
            setSearchQuery(suggestion);
            setSuggestions([]);
        }
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) =>
                prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1
            );
        } else if (e.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) =>
                prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
            );
        } else if (e.key === 'Enter') {
            if (highlightedIndex !== -1) {
                console.log({ sg: suggestions[highlightedIndex] })
                handleSuggestionClick(suggestions[highlightedIndex]);
            }

            await handleSearch()
        }
    };

    const handleSearch = async () => {
        setLoading(true);

        try {
            const searchBody = {
                query: searchQuery,
                max_hits: 20,
            };

            const response = await searchInAnIndex("5676732c-5348-4754-9c45-0b6a54d1a719", searchBody);
            setEvents(response.data.hits);
            setElapsedTime(response.data.elapsed_time_micros);
            setTotalCount(response.data.num_hits);
        } catch (error) {
            console.error('Failed to search:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setPageIndex(page);
    };

    const paginatedEvents = events.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    // Convert elapsed time to seconds or milliseconds
    const elapsedTimeInSeconds = (elapsedTime / 1e6).toFixed(2);
    const elapsedTimeInMilliseconds = (elapsedTime / 1e3).toFixed(2);
    const displayElapsedTime = elapsedTime >= 1e6 ? `${elapsedTimeInSeconds} s` : `${elapsedTimeInMilliseconds} ms`;

    // Format total count with commas
    const formattedTotalCount = totalCount.toLocaleString();

    const calculationListItems = [
        { title: 'Number of Documents', description: '70 Billion' },
        { title: 'Actual Storage Size', description: '29 TB' },
        { title: 'Indexed Storage Size', description: '12.5 TB' },
        { title: 'Indexed Storage Cost', description: '$285' },
        { title: 'Indexer Cost', description: '1 * $100 = $100' },
        { title: 'Number of Searchers', description: '10' },
        { title: 'Searcher Cost', description: '10 * $200 = $2000' },
        { title: '', description: '' },
        { title: 'Total Cost', description: '$2395' },
    ];

    return (
        <EuiPage paddingSize="l" style={{ display: 'flex', flexDirection: 'column' }}>
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' className='' style={{ padding: '0.5rem 0' }}>
                <EuiFlexItem
                    style={{
                        paddingLeft: "1rem",
                        paddingTop: "0.5rem",
                    }}
                >
                    <img
                        src={isDarkMode ? "/ssphere_white.png" : "/ssphere_black.png"}
                        alt="SearchSphere Logo"
                        style={{
                            height: '35px',
                            width: '160px',
                            marginRight: '8px'
                        }}
                        onClick={() => navigate('/')}
                    />
                </EuiFlexItem>
            
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem grow={false} style={{ width: "70%", marginTop: "10%", overflow: 'hidden' }}>
                    <EuiTitle size="l" style={{ textAlign: "center" }}>
                        <h1 style={{ color: '#f0f0f0' }}>Index & search 70 billion public files under $2500</h1>
                    </EuiTitle>
                    <EuiSpacer size="xl" />

                    {/* Subtitle Section */}
                    <EuiText textAlign="center" color="subdued">
                        <p style={{}}>
                            Start typing and filter public files by different params
                        </p>
                    </EuiText>

                    <EuiSpacer size="xl" />
                    {/* Search Form Section */}

                    <EuiFlexGroup justifyContent="center" gutterSize="l">
                        <EuiFlexItem grow={false} style={{ minWidth: '60%' }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: 'var(--euiColorEmptyShade)',
                                borderRadius: '5px',
                                border: '1px solid var(--euiColorLightShade)',
                                minHeight: '50px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                                position: 'relative',
                            }}>
                                <div style={{ flexGrow: 1, position: 'relative' }}>
                                    <input
                                        type="text"
                                        className='demo-searchbar'
                                        placeholder="Search for public files ..."
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        ref={inputRef}
                                        style={{
                                            width: '100%',
                                            padding: '20px',
                                            backgroundColor: 'var(--euiColorEmptyShade)',
                                            color: 'var(--euiTextColor)',
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                    />
                                    {suggestions.length > 0 && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '100%',
                                                left: 0,
                                                width: '100%',
                                                backgroundColor: 'var(--euiColorEmptyShade)',
                                                boxShadow: 'var(--euiShadowMedium)',
                                                border: '1px solid var(--euiColorLightShade)',
                                                zIndex: 10,
                                            }}
                                        >
                                            {suggestions.map((suggestion, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleSuggestionClick(suggestion)}
                                                    style={{
                                                        padding: '10px',
                                                        cursor: 'pointer',
                                                        backgroundColor: highlightedIndex === index ? '#0073e6' : '#2a2a2a',
                                                        color: highlightedIndex === index ? '#fff' : '#f0f0f0',
                                                    }}
                                                >
                                                    {suggestion}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div style={{
                                    padding: 0, minHeight: '50px',
                                }}>
                                    <EuiButton
                                        color="success"
                                        fill
                                        style={{
                                            width: '100%',
                                            minHeight: '50px',
                                        }}
                                        isLoading={loading}
                                        onClick={handleSearch}
                                    >Go!</EuiButton>
                                </div>
                            </div>

                            <EuiSpacer size="xxl" />

                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiSpacer size="xxl" />

                    {/* Display elapsed time and total count */}
                    {paginatedEvents.length > 0 && <EuiCallOut title={`Found ${formattedTotalCount} results in ${displayElapsedTime}`} color="primary" iconType="search">

                    </EuiCallOut>}


                    <EuiSpacer size="m" />

                    <EuiFlexGroup direction="column" gutterSize='0'>
                        {paginatedEvents.map((event, index) => (
                            <EuiFlexItem key={event?._id} style={{
                                marginBottom: '10px',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            }}>
                                <ReactJson src={event || {}}
                                    theme={isDarkMode ? "monokai" : "rjv-default"}
                                    style={{
                                        padding: '10px',
                                        borderRadius: "10px"
                                    }}
                                    displayDataTypes={false}
                                    displayObjectSize={true}
                                    enableClipboard={true}
                                    name={null}
                                    collapsed={index !== 0}
                                    onEdit={(edit) => {
                                        console.log(edit)
                                    }}>

                                </ReactJson>
                            </EuiFlexItem>
                        ))}
                    </EuiFlexGroup>

                    <EuiSpacer size="l" />

                    {paginatedEvents.length > 0 && <EuiFlexGroup justifyContent="center">
                        <EuiFlexItem grow={false}>
                            <EuiPagination
                                pageCount={Math.ceil(events.length / pageSize)}
                                activePage={pageIndex}
                                onPageClick={handlePageChange}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>}

                </EuiFlexItem>

                <EuiFlexItem grow={1} style={{ maxWidth: "30%", position: 'fixed', top: '1%', right: "0" }}>
                    {/* Info Panel Section */}
                    <EuiFlexGroup justifyContent="center">
                        <EuiFlexItem grow={false} style={{ maxWidth: 600 }}>
                            <EuiPanel paddingSize="l" style={{}}>
                                <EuiTitle size="s">
                                    <h3 style={{}}>What this does? </h3>
                                </EuiTitle>
                                <EuiSpacer size="m" />
                                <EuiText>
                                    <p style={{}}>
                                        This app searches through 70 billion public files, with each field being fully searchable. Each file object includes a payload property unique to each file type, demonstrating that SearchSphere supports dynamic mapping for schemaless indexing.
                                    </p>
                                </EuiText>

                                <EuiSpacer size="l" />

                                <EuiTitle size="s">
                                    <h3 style={{}}>How much does this cost? </h3>
                                </EuiTitle>
                                <EuiSpacer size="m" />
                                <EuiText>
                                    We used SearchSphere to index data about <strong>70 billion</strong> public files.
                                    <br></br>
                                    The total raw size of the files is <strong>29 TB</strong>.
                                    <br>
                                    </br>
                                    The index, stored on Amazon S3, now totals <strong>12.5 TB</strong>, with our indexer indexing at a speed of <strong>200 GB/day</strong>.
                                    <br></br>
                                    We leverage 10 stateless Amazon EC2 instances to query this extensive index, returning matching query phrases in just a few seconds.
                                </EuiText>


                                <EuiSpacer size="l" />

                                <EuiTitle size="s">
                                    <h3 style={{}}>How much does this cost? </h3>
                                </EuiTitle>
                                <EuiSpacer size="m" />
                                <EuiDescriptionList
                                    color='subdued'
                                    listItems={calculationListItems}
                                    type="column"
                                    align="right"
                                />
                            </EuiPanel>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPage>
    );
};

export default DemoSearchPage;