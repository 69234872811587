import React, { useState, useEffect } from 'react';
import {
    EuiPageTemplate,
    EuiText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiPanel,
    EuiTabs,
    EuiTab,
    EuiSpacer,
    EuiTitle,
    EuiIcon,
    EuiLink,
    EuiCard
} from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css'; // Use EUI light theme
import { Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';

import IndexOverview from './IndexOverview';
import IndexDocumentsView from './IndexDocumentsView';
import IndexMappings from './IndexMappings';
import { getIndexById, getIndexMappingByIndexId } from '../../utils/api';
import DeleteRequests from './DeleteRequests';
import CreateDeleteRequest from './CreateDeleteRequest';

const IndexDetailView = ({ indexId, deploymentId }) => {
    const location = useLocation();
    const [indexDetails, setIndexDetails] = useState(null);
    const [indexMappings, setIndexMappings] = useState(null);
    const [loading, setLoading] = useState(true);

    const tabs = [
        {
            id: 'overview',
            name: 'Overview',
            link: `/deployments/view/${deploymentId}/app/indices/details/${indexId}/overview`
        },
        {
            id: 'documents',
            name: 'Documents',
            link: `/deployments/view/${deploymentId}/app/indices/details/${indexId}/documents`
        },
        {
            id: 'indexMappings',
            name: 'Index mappings',
            link: `/deployments/view/${deploymentId}/app/indices/details/${indexId}/index-mappings`
        },        
        {
            id: 'deleteRequests',
            name: 'Delete Requests',
            link: `/deployments/view/${deploymentId}/app/indices/details/${indexId}/delete-requests`
        },
    ];

    useEffect(() => {
        const fetchIndexDetails = async () => {
            try {
                const response = await getIndexById(indexId);
                setIndexDetails(response.data);
            } catch (error) {
                console.error('Error fetching index details:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchIndexMappings = async () => {
            try {
                const response = await getIndexMappingByIndexId(indexId);
                setIndexMappings(response.data);
            } catch (error) {
                console.error('Error fetching index mappings:', error);
            }
        };


        fetchIndexDetails();
        fetchIndexMappings();
    }, [indexId]);

    if (loading) {
        return <EuiText>Loading...</EuiText>;
    }

    if (!indexDetails) {
        return <EuiText>Error loading index details.</EuiText>;
    }

    const fieldNames = indexDetails.mappings?.searchSettings?.map(setting => setting.fieldName) || [];

    return (
        <>
            <EuiSpacer size="l" />

            {/* Header */}
            <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <EuiTitle size="l">
                        <h1>{indexDetails.index_name}</h1>
                    </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton iconType="eye" href={`/deployments/view/${deploymentId}/app/console`} fill>
                        View in Playground
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="l" />

            {/* Tabs */}
            <EuiTabs>
                {tabs.map((tab) => (
                    <EuiTab
                        key={tab.id}
                        isSelected={location.pathname === tab.link} // Highlight the selected tab
                    >
                        <Link to={tab.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            {tab.name}
                        </Link>
                    </EuiTab>
                ))}
            </EuiTabs>

            <EuiSpacer size="l" />

            {/* Render the corresponding tab content based on the route */}
            <Routes>
                <Route path="/" element={<Navigate to="overview" />} />
                <Route path="overview" element={<IndexOverview deploymentId={deploymentId} indexDetails={indexDetails} />} />
                <Route path="documents" element={<IndexDocumentsView deploymentId={deploymentId} indexFields={[...fieldNames, "AND", "OR"]} />} />
                <Route path="index-mappings" element={<IndexMappings deploymentId={deploymentId} indexMappingsData={indexMappings} />} />
                <Route path="delete-requests" element={<DeleteRequests deploymentId={deploymentId} indexId={indexId}/>} />
                <Route path="create-delete-requests" element={<CreateDeleteRequest deploymentId={deploymentId} indexId={indexId} fieldNames={fieldNames} />} />
            </Routes>
        </>
    );
};

export default IndexDetailView;